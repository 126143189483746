import React, { useEffect } from 'react';
import { PreferenceSwitch } from './preference-switch';
import { FaSpinner } from 'react-icons/fa';

export type Preference = {
  key: string;
  templateKey?: string;
  title: string;
  description: string | React.ReactNode;
  checked: boolean;
  custom?: boolean;
  disabled?: boolean;
  showActions?: boolean;
  showToggle?: boolean;
};

export type PreferenceGroup = {
  title: string;
  preferences: Preference[];
  loading?: boolean;
  actions?: React.ReactNode | React.ReactNode[];
};

export type PreferencesFormProps = {
  title?: string;
  description: string | React.ReactNode;
  actions?: React.ComponentType<{
    preference: Preference;
  }>;
  onChange?: (key: string) => (enabled: boolean) => Promise<void>;
} & (
  | {
      grouped: true;
      preferences: PreferenceGroup[];
    }
  | {
      grouped?: false;
      preferences: Preference[];
    }
);

export const PreferencesForm = ({
  title,
  description,
  actions: ActionsComponent,
  grouped,
  preferences,
  onChange,
}: PreferencesFormProps) => {
  useEffect((): void => {
    if (title) {
      document.title = `${title}`;
    }
  }, [title]);

  return (
    <>
      {title ? <h2>{title}</h2> : null}
      <div className="company-profile__page-content flex flex-col">
        {grouped ? (
          preferences.map(({ title, preferences, actions, loading }) => (
            <div className="card card-vertical">
              <div className="flex flex-row items-center justify-between">
                <h3>{title}</h3>

                {actions}
              </div>

              {loading ? (
                <div className="flex flex-row items-center justify-center py-6">
                  <FaSpinner className="animate-spin text-gray-600 ml-2" />
                </div>
              ) : null}

              {preferences.map((a: Preference) => (
                <PreferenceSwitch
                  key={a.key}
                  id={a.key}
                  checked={a.checked}
                  title={a.title}
                  description={a.description}
                  onChange={onChange?.(a.key)}
                  disabled={a.disabled}
                  hideSwitch={a.showToggle === false}
                  actions={ActionsComponent && a.showActions ? <ActionsComponent preference={a} /> : null}
                />
              ))}
            </div>
          ))
        ) : (
          <div className="card card-vertical">
            {description ? typeof description === 'string' ? <p>{description}</p> : description : null}
            {preferences.map((a) => (
              <PreferenceSwitch
                key={a.key}
                id={a.key}
                checked={a.checked}
                title={a.title}
                description={a.description}
                onChange={onChange?.(a.key)}
                disabled={a.disabled}
                actions={ActionsComponent && a.showActions ? <ActionsComponent preference={a} /> : null}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};
