import { ButtonHTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import classnames from 'classnames';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-small'
  | 'tertiary-cancel'
  | 'tertiary-cancel-small'
  | 'quaternary'
  | 'wrap-only';

type Props = {
  buttonType: ButtonType;
  /**
   * We have to override the existing unwanted styles with a high specificity selector.
   * This means additional selector properties outside the current specificity need to be applied as '!important'.
   * Tailwind CSS classes need to by applied with the '!' prefix.
   */
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const StyledButton = ({
  buttonType,
  children,
  className,
  type = 'button',
  ...rest
}: PropsWithChildren<Props>): ReactElement => {
  const isSmall = buttonType.endsWith('-small');
  const buttonTypeClass = `styled-button--${
    isSmall ? buttonType.substring(0, buttonType.indexOf('-small')) : buttonType
  }`;
  const cssClasses = classnames('styled-button', isSmall && 'styled-button--small', buttonTypeClass, className);

  return (
    <button className={cssClasses} type={type} {...rest}>
      {children}
    </button>
  );
};

export default StyledButton;
