import React from 'react';
import { DatagridHeadProps } from './types';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { DatagridFilter } from './datagrid-filter';
import { Icon } from '../icon/icon';
import { BsSearch } from 'react-icons/bs';

export const DatagridHead = ({ onSearchChange, actions = [], ...props }: DatagridHeadProps) => {
  const { t } = useTranslation();

  const onChange = debounce(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onSearchChange?.(evt.target.value);
    },
    750,
    { leading: true, trailing: true, maxWait: 1500 },
  );

  return (
    <div className="datagrid-head">
      {onSearchChange ? (
        <div className="datagrid-head-left">
          <div className="datagrid-search form-input-container">
            <fieldset className="with-icon">
              <Icon icon={BsSearch} className="form-input-icon" />
              <input
                className="form-input"
                type="text"
                data-testid="datagrid-search"
                placeholder={t('search')}
                aria-label={t('search')}
                onChange={onChange}
              />
            </fieldset>
          </div>
        </div>
      ) : null}

      {actions || props.filterable ? (
        <div className="datagrid-head-right">
          {actions ? <div className="datagrid-actions">{actions}</div> : null}

          {props.filterable ? (
            <DatagridFilter
              filterable={props.filterable}
              filterForm={props.filterForm}
              filterState={props.filterState}
              onFilterChange={props.onFilterChange}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
