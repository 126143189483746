import { ReactNode } from 'react';

export type Asset<T extends XPosition> = {
  sizeAndPosition?: SizeAndPosition<T>;
  src: ReactNode;
};

export enum PageType {
  General = 'general',
  Maintenance = 'maintenance',
  NotFound = 'notFound',
  Unauthorized = 'unauthorized',
}

export type Props = {
  bottomAsset?: Asset<XPosition.Left>;
  contentKey: PageType;
  topAsset?: Asset<XPosition.Right>;
} & (
  | {
      displayHomeButton: boolean;
      displayLoginButton?: never;
    }
  | {
      displayHomeButton?: never;
      displayLoginButton: boolean;
    }
);

export type SizeAndPosition<T extends XPosition> = {
  [P in T]: number;
} & {
  height: number;
  top: number;
  width: number;
};

export enum XPosition {
  Left = 'left',
  Right = 'right',
}
