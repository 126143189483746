import { GenericTemplatesBackendAdapter, TemplateContent, TemplateType } from '../types';
import { useTemplateDynamicData } from './useTemplateDynamicData';
import { useTemplates } from './useTemplates';
import { useUpdateTemplate } from './useUpdateTemplate';
import { useDeleteTemplate } from './useDeleteTemplate';
import { useBuildEmailTemplatePreview } from './useBuildEmailTemplatePreview';

export const defaultGenericTemplateBackendAdapter = <Content extends TemplateContent>({
  apiBaseUrl,
  employerId,
  templateType,
}: {
  apiBaseUrl: string;
  employerId: string;
  templateType: TemplateType;
}): GenericTemplatesBackendAdapter<Content> => {
  return {
    useTemplates: ({ templateName }) => useTemplates({ apiBaseUrl, employerId, templateType, templateName }),
    useUpdateTemplate: ({ templateName, onTemplateUpdated }) =>
      useUpdateTemplate({
        apiBaseUrl,
        employerId,
        templateType,
        templateName,
        onTemplateUpdated,
      }),
    useDeleteTemplate: ({ templateName, onTemplateDeleted }) =>
      useDeleteTemplate({
        apiBaseUrl,
        employerId,
        templateType,
        templateName,
        onTemplateDeleted,
      }),
    useTemplateDynamicData: ({ templateName }) =>
      useTemplateDynamicData({ apiBaseUrl, employerId, templateType, templateName }),
    useBuildEmailTemplatePreview: ({ emailType }) =>
      useBuildEmailTemplatePreview({
        apiBaseUrl,
        employerId,
        emailType,
      }),
  };
};
