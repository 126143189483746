import { Button, ButtonColor, ButtonVariant, FormInput, FormPasswordInput } from '@alirosoftware/web-components';
import cx from 'classnames';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useAttemptLogin from '../../authentication/useAttemptLogin';
import { useLoginSignupContext } from '../../context/LoginSignupModalContext';
import AuthErrorMessage from './AuthErrorMessage';

const initialValues = {
  email: '',
  password: '',
};

type Props = {
  onForgotPassword?: () => void;
  erpSignupUrl?: string;
  getToken: () => Promise<string>;
  onMfaError: (token: string) => void;
};

const EmailLoginForm: FC<Props> = ({ erpSignupUrl, onForgotPassword, getToken, onMfaError }) => {
  const { t } = useTranslation();
  const { mutate, ...loginMutation } = useAttemptLogin();
  const { closeLoginSignupModal } = useLoginSignupContext();

  const schema = yup.object().shape({
    email: yup.string().email().required().label(t('email')),
    password: yup.string().required().label(t('password')),
  });

  const handleSubmit = async (values: typeof initialValues) => {
    const token = await getToken();
    if (typeof token === 'string') {
      mutate(
        { ...values, token: token },
        {
          onSuccess: closeLoginSignupModal,
          onError: ({ response }) => {
            if (response.status === 428 && response.data.token) {
              return onMfaError(response.data.token);
            }
          },
        },
      );
    }
  };

  const errorData = loginMutation.error?.response?.data;

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} validateOnBlur={false}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} data-cy="login-form">
            <div className="w-full flex flex-col">
              <FormInput
                name="email"
                type="email"
                label={t('email')}
                className="!normal-case w-full"
                placeholder={t('enter') + ' ' + t('emailAddress')}
                displayValidInput={false}
              />
              <FormPasswordInput
                name="password"
                label={t('password')}
                placeholder={t('enter') + ' ' + t('password')}
                className="u-full-width"
                displayValidInput={false}
              />
              {onForgotPassword && (
                <div className="w-full text-right -mt-[8px] mb-[16px]">
                  <Button
                    variant={ButtonVariant.Text}
                    color={ButtonColor.Blue}
                    className="!text-16 !mt-0 !font-normal"
                    onClick={onForgotPassword}
                    type="button"
                  >
                    {t('forgotPassword')}
                  </Button>
                </div>
              )}
              {loginMutation.isError && !Array.isArray(errorData) && (
                <AuthErrorMessage>{errorData['message']}</AuthErrorMessage>
              )}
              {/** For when they enter an email/password that fails the JSON schema validation, e.g. a too short password */}
              {loginMutation.isError && Array.isArray(errorData) && (
                <AuthErrorMessage>{t('passport.invalidPassEmail')}</AuthErrorMessage>
              )}
              {!!erpSignupUrl && (
                <a
                  className={cx({
                    button: true,
                    'button-primary': true,
                    'u-full-width': true,
                  })}
                  href={erpSignupUrl}
                >
                  {t('signUpVia')} {new URL(erpSignupUrl).hostname}
                </a>
              )}
              <Button
                type="submit"
                color={!!erpSignupUrl ? ButtonColor.Blue : ButtonColor.Primary}
                showLoader
                loading={loginMutation.isLoading || loginMutation.isSuccess}
              >
                {t('logIn')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EmailLoginForm;
