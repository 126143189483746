import React, { PropsWithChildren } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { IoMdClose } from 'react-icons/io';
import classnames from 'classnames';

export type ModalSize = 'small' | 'default' | 'medium' | 'large';

export type ModalProps = PropsWithChildren<{
  open: boolean;
  className?: string;
  onClose?: () => void;
  showCloseIcon?: boolean;
  canClose?: boolean;
  size?: ModalSize;
  overlay?: boolean;
  onInteractOutside?: (e: Event) => void;
  disableAutoFocus?: boolean;
  modal?: boolean;
  trigger?: React.ReactNode;
  mobileAppCard?: boolean;
}>;

export const Modal = ({
  open,
  className,
  children,
  onClose,
  modal,
  size = 'default',
  showCloseIcon = true,
  canClose = true,
  overlay = true,
  disableAutoFocus = false,
  onInteractOutside,
  trigger,
  mobileAppCard,
}: ModalProps) => {
  const onOpenChange = (open: boolean) => {
    if (!open) {
      onClose?.();
    }
  };

  const onOpenAutoFocus = !disableAutoFocus
    ? undefined
    : (e: Event) => {
        e.preventDefault();
      };

  return (
    <Dialog.Root open={open} onOpenChange={canClose ? onOpenChange : undefined} modal={modal}>
      {!!trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        {overlay ? (
          modal === false ? (
            <div
              data-state="open"
              className="modal-overlay"
              style={{ pointerEvents: 'auto' }}
              data-aria-hidden="true"
              aria-hidden="true"
            />
          ) : (
            <Dialog.Overlay className="modal-overlay" />
          )
        ) : null}
        <Dialog.Content
          className={classnames(
            'modal',
            className,
            size !== 'default' ? `modal--${size}` : null,
            mobileAppCard && 'modal--app-card',
          )}
          tabIndex={undefined}
          onInteractOutside={onInteractOutside}
          onOpenAutoFocus={onOpenAutoFocus}
        >
          {children}
          {showCloseIcon ? (
            <Dialog.Close className="close" role="button" aria-label="Close" asChild>
              <div className="close">
                <IoMdClose />
              </div>
            </Dialog.Close>
          ) : null}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
