import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import * as yup from 'yup';

import { translations } from '@alirosoftware/aliro-common';

import browserDetection from './browserDetection';
import { buildYupLocale } from './utils';

const constants = {
  fallbackLng: ['en-US'],
  availableLanguages: ['de-DE', 'en-GB', 'en-US', 'es-ES', 'es-419', 'fr-CA', 'it-IT'],
  ns: ['translation'],
  defaultNS: 'translation',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
};

const fallbackLng = constants.fallbackLng;
const ns = constants.ns;
const defaultNS = constants.defaultNS;
const debug = constants.debug;
const interpolation = constants.interpolation;
const react = constants.react;

const resources = {
  ['de-DE']: {
    translation: translations.GermanTranslation,
  },
  ['en-GB']: {
    translation: translations.UkEnglishTranslation,
  },
  ['en-US']: {
    translation: translations.UsEnglishTranslation,
  },
  ['es-ES']: {
    translation: translations.SpanishTranslation,
  },
  ['es-419']: {
    translation: translations.LatAmSpanishTranslation,
  },
  ['fr-CA']: {
    translation: translations.CaFrenchTranslation,
  },
  ['it-IT']: {
    translation: translations.ItalianTranslation,
  },
};

const options = {
  order: ['detector'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['detector'],
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(browserDetection());

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init(
    {
      detection: options,
      fallbackLng,
      ns,
      defaultNS,
      debug,
      interpolation,
      react,
      resources,
    },
    (err, t) => {
      yup.setLocale(buildYupLocale(t));
    },
  );

i18n.on('languageChanged', (lng) => {
  yup.setLocale(buildYupLocale(i18n.getFixedT(lng)));
});

export default i18n;
