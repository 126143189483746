import { createContext } from 'react';
import { AliroConfig } from '../../../global';

export type AppContext = {
  config: AliroConfig;
  loading: boolean;
  setLoading: (value: boolean) => void;
  siteIsMilitary?: boolean;
};

export default createContext<AppContext>({
  config: {} as AliroConfig,
  loading: false,
  setLoading: (value: boolean): void => {},
  siteIsMilitary: false,
});
