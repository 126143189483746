import { createContext, PropsWithChildren, useContext, useState } from 'react';

export type WelcomeContextValue = {
  welcomeModalOpen: boolean;
  setWelcomeModalOpen: (open: boolean) => void;
};

export const WelcomeContext = createContext<WelcomeContextValue>({
  welcomeModalOpen: false,
  setWelcomeModalOpen: () => {},
});

export const WelcomeProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);

  return (
    <WelcomeContext.Provider
      value={{
        welcomeModalOpen: open,
        setWelcomeModalOpen: setOpen,
      }}
    >
      {children}
    </WelcomeContext.Provider>
  );
};

export const useWelcomeContext = () => useContext(WelcomeContext);

export const withWelcomeContext = (Component: any) => (props: any) => (
  <WelcomeContext.Consumer>{(context) => <Component {...props} welcomeContext={context} />}</WelcomeContext.Consumer>
);
