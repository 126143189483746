import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useLoader } from '../../../hooks/useLoader';
import {
  DynamicDataTemplate,
  GetTemplateDynamicDataResponse,
  TemplateType,
  UseTemplateDynamicDataResult,
} from '../types';

export type UseTemplateDynamicDataProps = {
  apiBaseUrl: string;
  employerId: string;
  templateType: TemplateType;
  templateName: string;
};

export const useTemplateDynamicData = ({
  apiBaseUrl,
  employerId,
  templateType,
  templateName,
}: UseTemplateDynamicDataProps): UseTemplateDynamicDataResult => {
  const { loaded, loading, error, cancelLoading, run } = useLoader({ cancelOnUnmount: true });

  const [templates, setTemplates] = useState<DynamicDataTemplate[]>([]);

  const loadData = useCallback(
    async (type: string) => {
      cancelLoading();
      await run(
        axios.get<GetTemplateDynamicDataResponse>(
          `/api/v2/employer/${employerId}/templates/${templateType}/${type}/dynamic-data`,
          {
            baseURL: apiBaseUrl,
          },
        ),
      )?.then((result) => {
        if (!result) {
          // Cancelled
          return;
        }

        if (result.data?.success) {
          setTemplates(result.data.templates);
        } else {
          throw new Error(result.data?.error || 'Unknown error');
        }
      });
    },
    [apiBaseUrl, employerId, templateType, run, cancelLoading],
  );

  const reload = useCallback(() => {
    return loadData(templateName);
  }, [loadData, templateName]);

  useEffect(() => {
    loadData(templateName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateName]);

  return {
    error,
    loaded,
    loading,
    reload,
    templates,
  };
};
