import React from 'react';
import { Switch } from '../core/switch/switch';
import { Box } from '../core/layout/box/box';

export type PreferenceSwitchProps = {
  id: string;
  checked: boolean;
  disabled?: boolean;
  hideSwitch?: boolean;
  onChange?: (enabled: boolean) => Promise<void>;
  title: string;
  description: string | React.ReactNode;
  actions: React.ReactNode;
};

export const PreferenceSwitch = ({
  id,
  checked: initialChecked,
  onChange: onChangeProp,
  title,
  description,
  actions,
  disabled,
  hideSwitch,
}: PreferenceSwitchProps) => {
  const [checked, setChecked] = React.useState<boolean>(initialChecked);
  const onChange = (enabled: boolean) => {
    if (disabled) return;

    setChecked(enabled);
    onChangeProp?.(enabled);
  };

  return (
    <div className="preference" key={id}>
      {!hideSwitch ? (
        <Switch
          checked={checked}
          id={id}
          label={title}
          onChange={onChange}
          subLabel={description}
          disabled={disabled}
        />
      ) : title || description ? (
        <Box display="flex" flexDirection="column" className="switch-info">
          {title ? (
            <label className="label text-offblack" htmlFor={id}>
              {title}
            </label>
          ) : null}
          {description ? (
            <label className="sublabel text-offblack text-14 font-normal leading-tight" htmlFor={id}>
              {description}
            </label>
          ) : null}
        </Box>
      ) : null}

      {actions && <div className="preference-actions">{actions}</div>}
    </div>
  );
};
