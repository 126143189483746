// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyD8bSUoitAjBFOjoa66_a0Ch_RuAA4MKi8',
  authDomain: 'aliro-stage-213618.firebaseapp.com',
  projectId: 'aliro-stage-213618',
  storageBucket: 'aliro-stage-213618.appspot.com',
  messagingSenderId: '959488992544',
  appId: '1:959488992544:web:6539bbb9cd3907e35f2345',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
