export function getLanguage(language: string) {
  if (!language) {
    return 'en-US';
  }

  const validLanguages = ['de-DE', 'en-GB', 'en-US', 'es-ES', 'es-419', 'fr-CA', 'it-IT'];

  // Handle exact matches
  if (validLanguages.includes(language)) {
    return language;
  }

  const langStart = language.substring(0, 2);
  switch (langStart) {
    case 'de':
      return 'de-DE';
    case 'en':
      return 'en-US';
    case 'es':
      return 'es-ES';
    case 'fr':
      return 'fr-CA';
    case 'it':
      return 'it-IT';
    default:
      return 'en-US';
  }
}
