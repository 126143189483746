import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  FormCheckboxInput,
  FormInput,
  FormPasswordInput,
  FormSelectInput,
} from '@alirosoftware/web-components';
import { FC, useMemo } from 'react';
import * as yup from 'yup';
import useCreateAccount from '../../hooks/useCreateAccount';
import { useLoginSignupContext } from '../../context/LoginSignupModalContext';
import Options from '../../constants/Options';

type Props = {
  onAlreadyHasAccount: () => void;
  onRequiresVerification: () => void;
};

const SignUpForm: FC<Props> = ({ onAlreadyHasAccount, onRequiresVerification }) => {
  const { t, i18n } = useTranslation();

  const loginContext = useLoginSignupContext();
  const createAccount = useCreateAccount();

  const handleSubmit = async (values) => {
    await i18n.changeLanguage(values.defaultLanguage);

    const user = await createAccount.mutateAsync({
      ...values,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
    });

    if (user.isVerified === false) {
      onRequiresVerification();
    }
  };

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .required(`${t('firstName')} ${t('isRequired')}`)
          .max(40, t('validation.fieldMustBeLessThan').replace('{{value}}', '40').replace('{{field}}', t('firstName')))
          .matches(/^(?:[a-zA-Z0-9]+[ ]?[a-zA-Z0-9]+)+$/, `${t('firstName')} ${t('isInvalid')}`),
        lastName: yup
          .string()
          .required(`${t('lastName')} ${t('isRequired')}`)
          .max(40, t('validation.fieldMustBeLessThan').replace('{{value}}', '40').replace('{{field}}', t('lastName')))
          .matches(/^(?:[a-zA-Z0-9]+[ ]?[a-zA-Z0-9]+)+$/, `${t('lastName')} ${t('isInvalid')}`),
        email: yup
          .string()
          .email()
          .required(`${t('email')} ${t('isRequired')}`)
          .max(80, t('validation.fieldMustBeLessThan').replace('{{value}}', '80').replace('{{field}}', t('email'))),
        password: yup
          .string()
          .required(`${t('password')} ${t('isRequired')}`)
          .min(8, t('passwordValidations.min').replace('{min}', '8'))
          .max(128, t('passwordValidations.max'))
          .test({
            message: t('passwordValidations.uppercase'),
            test: (value) => {
              return /[A-Z]/.test(value);
            },
          })
          .test('passwordValidations.lowercase', t('passwordValidations.lowercase'), (value) => /[a-z]/.test(value))
          .test('passwordValidations.number', t('passwordValidations.number'), (value) => /[0-9]/.test(value))
          .test('passwordValidations.specialCharacter', t('passwordValidations.symbol'), (value) =>
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value),
          ),
        defaultLanguage: yup.string().required(`${t('defaultLanguage')} ${t('isRequired')}`),
        veteran: yup.boolean().required(),
        ...(loginContext.showJobFields
          ? {
              jobTitle: yup.string().required(`${t('jobTitle')} ${t('isRequired')}`),
              jobFunction: yup.string(),
            }
          : {}),
      }),
    [loginContext.showJobFields],
  );

  const buttonLoading = createAccount.isLoading || createAccount.isSuccess;

  return (
    <Formik
      initialValues={{
        email: loginContext.email ?? '',
        password: '',
        firstName: loginContext.firstName ?? '',
        lastName: loginContext.lastName ?? '',
        veteran: loginContext.veteran ?? false,
        defaultLanguage: i18n.language,
        ...(loginContext.showJobFields
          ? {
              jobTitle: loginContext.jobTitle ?? '',
              jobFunction: loginContext.jobFunction ?? '',
            }
          : {}),
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({}) => (
        <Form className="flex flex-col">
          {
            <>
              <FormInput name="firstName" label={t('firstName')} placeholder={t('firstName')} />
              <FormInput name="lastName" label={t('lastName')} placeholder={t('lastName')} />
              <FormInput name="email" label={t('email')} placeholder={t('email')} />
              <FormPasswordInput name="password" label={t('password')} placeholder={t('password')} />
              {onAlreadyHasAccount && (
                <div className="w-full text-center my-[12px]">
                  <Button
                    variant={ButtonVariant.Text}
                    color={ButtonColor.Blue}
                    className="!text-16 !underline !font-normal !m-0"
                    onClick={onAlreadyHasAccount}
                    type="button"
                  >
                    {t('haveAnAccountAlready?')}
                  </Button>
                </div>
              )}
              <FormSelectInput
                name="defaultLanguage"
                label={t('chooseDefaultLanguage')}
                options={{
                  'en-US': 'English (US)',
                  'en-GB': 'English (GB)',
                  'es-ES': 'Español',
                  'es-419': 'Español (LatAm)',
                  'de-DE': 'Deutsch',
                  'fr-CA': 'Français (Canada)',
                  'it-IT': 'Italiano',
                }}
              />
              {loginContext.showJobFields && (
                <>
                  <FormInput name="jobTitle" label={t('jobTitle')} placeholder={t('jobTitle')} />
                  <FormSelectInput name="jobFunction" label={t('jobFunction')}>
                    {Options.jobFunctionOptional}
                  </FormSelectInput>
                </>
              )}
              <label className="flex flex-row justify-start">
                <div>
                  <FormCheckboxInput name="veteran" />
                </div>
                <div className="flex-1">
                  <div className="font-bold">{t('militaryAffiliated')}</div>
                  <div className="font-normal">({t('activeDutyReserve')})</div>
                </div>
              </label>
            </>
          }
          <div className="accept-terms">
            {t('registeringAgree')}
            <br />
            <a className="text-blue-800 no-margin-anchor" href="/terms-of-use">
              {t('termsOfService')}
            </a>{' '}
            {t('and')}{' '}
            <a className="text-blue-800 no-margin-anchor" href="/privacy-policy">
              {t('privacyPolicy')}
            </a>
          </div>
          {!!createAccount.error && (
            <div className="bg-error/10 text-error rounded my-[16px] px-[16px] py-[8px]">{createAccount.error}</div>
          )}
          <Button type="submit" className="mx-auto" color={ButtonColor.Primary} loading={buttonLoading} showLoader>
            {t('createAccount')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
