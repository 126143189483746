import { useTranslation } from 'react-i18next';
import { BsCheckCircle } from 'react-icons/bs';

const VerifyEmailPrompt = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <BsCheckCircle className="text-success text-[100px] mb-[16px]" />
      <div className="text-20 font-medium">{t('thanksForSigningUp')}</div>
      <div>{t('oneMoreThingVerify')}</div>
    </div>
  );
};

export default VerifyEmailPrompt;
