import React, { useEffect, useState } from 'react';
import { useDisplayModeContext } from '../../context/DisplayModeContext';
import { useLoginSignupContext } from '../../context/LoginSignupModalContext';
import { useAfterLogin } from '../../hooks/useAfterLogin';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';
import { useAuthContext } from '../../context/auth-context';

export const WebAuthnHandler = () => {
  const {
    user: { isLoggedIn, data: userData },
  } = useAuthContext();
  const { displayMode } = useDisplayModeContext();
  const { afterLogin } = useAfterLogin();
  const [hasTriedWebAuthn, setHasTriedWebAuthn] = useState(false);
  const { tryLoginWebAuthn } = useLoginSignupContext();

  useEffect(() => {
    if (isLoggedIn || hasTriedWebAuthn || !browserSupportsWebAuthn() || displayMode !== 'pwa') {
      return;
    }

    setHasTriedWebAuthn(true);
    tryLoginWebAuthn()
      .then((webAuthnResponse) => {
        if (webAuthnResponse) {
          afterLogin({
            detail: {
              userData: webAuthnResponse.userData,
              employerData: webAuthnResponse.employerData,
            },
            skipWebAuthn: true,
          });
        }
      })
      .catch((e) => {
        console.error('webAuthnResponse error', e);
      });
  }, [isLoggedIn, hasTriedWebAuthn, displayMode]);

  return null;
};
