import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { AppContextProvider } from './context/app-context';
import { AuthProvider } from './context/auth-context';
import { DisplayModeProvider } from './context/DisplayModeContext';
import { LoginSignupProvider } from './context/LoginSignupModalContext';
import { ReferralProvider } from './context/ReferralContext';
import { WelcomeProvider } from './context/WelcomeModalContext';
import { MaintenancePage } from './pages/error-page';
import { RouterProvider } from './router/RouterProvider';
import { RequestNotificationPermissionProvider } from './context/RequestNotificationPermissionContext';

const sentry = window.aliroConfig.sentry;
if (sentry === 'true') {
  Sentry.init({
    dsn: 'https://c88fbfc5abdcb8b0a9edc6c5356632eb@o97239.ingest.sentry.io/4505804360253440',
    environment: window.aliroConfig.coreUrl,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],

    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('react_container'));

const displayMaintenancePage = window.aliroConfig.displayMaintenancePage;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  displayMaintenancePage === 'true' ? (
    <MaintenancePage />
  ) : (
    <Suspense fallback="loading">
      <DisplayModeProvider>
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <AuthProvider>
              <WelcomeProvider>
                <LoginSignupProvider>
                  <ReferralProvider>
                    <RequestNotificationPermissionProvider>
                      <DndProvider backend={HTML5Backend}>
                        <RouterProvider />
                      </DndProvider>
                    </RequestNotificationPermissionProvider>
                  </ReferralProvider>
                </LoginSignupProvider>
              </WelcomeProvider>
            </AuthProvider>
          </AppContextProvider>
        </QueryClientProvider>
      </DisplayModeProvider>
    </Suspense>
  ),
);
