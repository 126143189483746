import { join } from 'path';

const getConfig = () => {
  const config = {};

  config.analytics = {};
  config.analytics.velocity = {};
  config.analytics.velocity.intermediateStatuses = ['expressed interest']; // We are not including 'unreviewed' here since the only way to get there is after being in another status
  config.analytics.flags = {};
  config.analytics.flags.recruiterVelocityInMins = 60 * 24 * 7; // 7 days
  config.analytics.flags.recruiterUnreviewedCandidates = 12;
  config.analytics.flags.recruiterLastActivityInMins = 60 * 24 * 7; // 7 days
  config.analytics.status = {};
  config.analytics.status.options = ['closed', 'favorited', 'hired', 'ignored', 'interviewing', 'unreviewed'];

  config.branding = {
    signup: {
      sanitizeHtml: {
        allowedAttributes: {
          a: ['class', 'href', 'name', 'rel', 'target', 'title'],
          div: ['class'],
          iframe: ['allow', 'allowfullscreen', 'frameborder', 'height', 'src', 'title', 'width'],
          img: ['alt', 'height', 'loading', 'src', 'srcset', 'title', 'width'],
        },
        allowedClasses: {
          a: ['featured-member', 'our-partner'],
          div: ['featured'],
        },
        allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
        allowedTags: ['iframe', 'img', 'a'],
      },
    },
  };

  config.email = {};
  config.email.sendIntervalInHours = 24 * 7 - 3; // 7 days minutes 3 hours to account for random send time

  config.task = {};
  config.task.referrerDigest = {};
  config.task.referrerDigest.time = '7:30';
  config.task.referrerDigest.days = ['tuesday'];
  config.task.employeeEngagement = {};
  config.task.employeeEngagement.days = 3;
  config.task.employeeEngagement.hours = 10;
  config.task.employeeEngagement.timezone = 'America/Chicago';

  config.gdpr = {};
  config.gdpr.slug = 'GDPR: Removal';

  config.candidate = {};
  config.candidate.percentProfileMinimum = 50;
  config.candidate.name = {};
  config.candidate.name.first = null;
  config.candidate.name.last = null;
  config.candidate.needProfileDelayInMins = 60; // How many minutes do we delay sending the candidate that we need their profile to be compelte
  config.candidate.ignoredDelayInMins = 24 * 60; // How many minutes do we delay sending the candidate that they've been ignored.
  config.candidate.status = {};
  config.candidate.status.options = [
    'closed',
    'favorited',
    'hired',
    'ignored',
    'interviewing',
    'removed',
    'unreviewed',
    'withdrawn',
    'employees',
  ];
  config.candidate.status.map = {
    closed: 'closed',
    employees: 'employees',
    favorited: 'screening',
    hired: 'hired',
    ignored: 'declined',
    interviewing: 'interview',
    removed: 'removed',
    unreviewed: 'open',
    withdrawn: 'withdrawn',
  };
  config.candidate.pipelines = {};
  config.candidate.pipelines.favorited = ['unreviewed', 'ignored', 'interviewing', 'hired'];
  config.candidate.pipelines.hired = [];
  config.candidate.pipelines.ignored = ['unreviewed', 'favorited', 'interviewing', 'hired'];
  config.candidate.pipelines.interviewing = ['unreviewed', 'ignored', 'favorited', 'hired'];
  config.candidate.pipelines.unreviewed = ['ignored', 'favorited', 'interviewing', 'hired'];
  config.candidate.pipelines.closed = [];
  config.candidate.pipelines.withdrawn = [];
  config.candidate.hiredEmailDelayInMins = 60 * 24 * 7; // 7 days hrs
  config.candidate.paypal = {};
  config.candidate.paypal.emailNeededDelayInMins = config.candidate.hiredEmailDelayInMins + 60 * 24 * 5; // 5 days after the hire email
  config.candidate.paypal.repeatEvery = '5 days';
  config.candidate.outreach = {};
  config.candidate.outreach.followup1 = 'OUT7';
  config.candidate.outreach.followup1DelayInDays = 7;
  config.candidate.outreach.followup2 = 'OUT30';
  config.candidate.outreach.followup2DelayInDays = 30;
  config.candidate.outreach.followup1Template = 'MAIL_CANDIDATE_INTEREST_OUTREACH';
  config.candidate.outreach.followup2Template = 'MAIL_CANDIDATE_INTEREST_OUTREACH_FOLLOWUP';

  config.referrer = {};
  config.referrer.withdrawInterestDelayInMins = 60; // How many minutes allowed for a candidate to withdraw interest before notifying referrer they've withdrawn interest
  config.referrer.expressInterestDelayInMins = 60; // How many minutes allowed before we email the referrer they expressed interest or email referrer they need to update profile
  config.referrer.digestThresholdInDays = 30;
  config.referrer.hiredEmailDelayInMins = 60 * 24; // 24 hrs
  config.referrer.paypal = {};
  config.referrer.paypal.emailNeededDelayInMins = config.referrer.hiredEmailDelayInMins + 60 * 24 * 5; // 5 days after the hire email
  config.referrer.paypal.repeatEvery = '5 days';

  config.templates = {};
  config.templates.email = join(__dirname, 'assets/email-templates');

  config.legal = {};
  config.legal.path = join(__dirname, 'assets/docs');
  config.legal.terms = 'March, 2023';
  config.legal.privacy = 'September 4th, 2023';

  config.job = {};
  config.job.editing = {};
  config.job.editing.allowedTags = ['p', 'u', 'i', 'em', 'b', 'strong', 'ul', 'li', 'br', 'a'];
  config.job.editing.allowedAttributes = {
    a: ['href'],
  };
  config.job.editing.transformTags = {
    a: (tagName, attribs) => {
      if (attribs.href && !(attribs.href.startsWith('http://') || attribs.href.startsWith('https://'))) {
        return {
          tagName: 'i',
          attribs: {},
        };
      }
      return {
        tagName,
        attribs,
      };
    },
  };

  config.logLevel = process.env.LOG_LEVEL || 'debug';

  config.resume = {};
  config.resume.extensionWarnings = ['png', 'jpg', 'jpeg', 'gif'];

  config.roles = {};
  config.roles.valid = [
    'admin',
    'core_user',
    'erp_admin',
    'erp_recruiter',
    'erp_user',
    'erp_payments',
    'erp_company_admin',
  ];
  config.roles.labels = [
    'Aliro Admin',
    'External User',
    'Administrator',
    'Recruiter',
    'Employee',
    'Payments Admin',
    'Company Admin',
  ];

  config.password = {};
  config.password.expireInMinutes = 60 * 24; // 24hours

  // structure permissions.(permGroup).(permName) = (permDescription)
  config.permissions = {};

  config.permissions.employer = {};
  config.permissions.employer.userDashboard = 'User Dashboard';
  config.permissions.employer.companyProfile = 'Manage Company Profile';
  config.permissions.employer.candidates = 'View All Candidates';
  config.permissions.employer.matches = 'View All Matches';
  config.permissions.employer.companyBranding = 'Manage Company Branding';
  config.permissions.employer.jobAssignment = 'Assign Recruiters to Jobs';
  config.permissions.employer.users = 'Administer Users';
  config.permissions.employer.platformOnboarding = 'Platform Onboarding';

  config.protocol = process.env.PROTOCOL || 'https';

  // structure rolePermissions.(role) = [ (permGroup).(permName), .... ]
  config.rolePermissions = {};
  config.rolePermissions.core_user = [];
  config.rolePermissions.admin = ['admin.users', 'admin.dashboard', 'admin.contacts'];
  config.rolePermissions.erp_admin = [
    'employer.matches',
    'employer.candidates',
    'employer.companyProfile',
    'employer.companyBranding',
    'employer.jobAssignment',
    'employer.users',
    'employer.filterJobsByRecruiter',
    'employer.userDashboard',
    'employer.platformOnboarding',
    'employer.customerAts',
  ];
  config.rolePermissions.erp_payments = [];
  config.rolePermissions.erp_user = [];
  config.rolePermissions.erp_recruiter = ['employer.matches', 'employer.candidates'];
  config.rolePermissions.erp_company_admin = [
    'employer.matches',
    'employer.candidates',
    'employer.companyProfile',
    'employer.companyBranding',
    'employer.jobAssignment',
    'employer.users',
    'employer.filterJobsByRecruiter',
    'employer.userDashboard',
    'employer.platformOnboarding',
    'employer.customerAts',
  ];

  // slack
  config.slack = {};
  config.slack.token = process.env.SLACK_TOKEN;
  config.slack.channels = {};
  config.slack.channels.integration = process.env.SLACK_CHANNEL_INTEGRATION;
  config.slack.channels.platform = process.env.SLACK_CHANNEL_PLATFORM;
  config.slack.channels.peoplesoft = process.env.SLACK_CHANNEL_PEOPLESOFT;

  // AliroMATCH settings
  config.match = {};
  config.match.server = {};
  config.match.server.url = process.env.MATCH_SERVER;

  // MOS codes
  config.MOSCodes = {};
  config.MOSCodes.branches = [
    { name: 'Air Force', alias: 'AF' },
    { name: 'Army', alias: 'ARMY' },
    { name: 'Coast Guard', alias: 'CG' },
    { name: 'Marines', alias: 'MARINES' },
    { name: 'Navy', alias: 'NAVY' },
  ];

  config.awardcoApi = {
    key: process.env.AWARDCO_API_KEY,
    rootEndpoint: process.env.AWARDCO_ROOT_ENDPOINT,
    url: process.env.AWARDCO_URL,
  };

  config.firstStudentHostname = process.env.FIRST_STUDENT_HOST;

  return config;
};

export default getConfig;
