const BUTTON_CONSTANTS = {
  NETWORK: {
    FACEBOOK: 'FACEBOOK',
    LINKEDIN: 'LINKEDIN',
    MESSENGER: 'MESSENGER',
    APPLE: 'APPLE',
    SSO: 'SSO',
    SSOEMPLOYEES: 'SSOEMPLOYEES',
  },
  TYPE: {
    SIGN_UP: 'SIGN_UP',
    SIGN_IN: 'SIGN_IN',
    SHARE: 'SHARE',
    SEND: 'SEND',
  },
};

export default BUTTON_CONSTANTS;
