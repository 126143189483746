import Cookies from 'cookies-js';
import SESSION_STORAGE_KEYS from '../constants/SessionStorageConstants';
import useAppContext from '../context/app-context';
import request from '../helpers';
import { addHasTriedWebAuthnCookie } from '../helpers/webauthn';

export const useLogout = () => {
  const { config } = useAppContext();
  const logout = async (postLogoutPath = '/') => {
    try {
      await request.post('auth/user/logout');
    } finally {
      sessionStorage.clear();
      sessionStorage.setItem('notLoggedIn', 'true');
      Cookies.expire(SESSION_STORAGE_KEYS.USER.NEXT_URL_AFTER_LOGIN, {
        // @ts-ignore
        domain: `.${config.platformDomain}`,
        secure: process.env.NODE_ENV === 'production',
      });
      Cookies.expire('currentEmployer', {
        // @ts-ignore
        domain: `.${config.platformDomain}`,
        secure: process.env.NODE_ENV === 'production',
      });
      window.location.href = postLogoutPath;
      addHasTriedWebAuthnCookie();
    }
  };

  return { logout };
};
