import { ReactElement, useEffect } from 'react';
import { ErrorPage } from './error-page';
import { PageType, Props } from './types';
import { useLocation, useNavigate } from 'react-router';
import { useDisplayModeContext } from '../../context/DisplayModeContext';

const notFoundNode: ReactElement = (
  <div className="absolute leading-[100%] inset-x-[-100%] lining-nums tabular-nums font-black text-center text-[368px] text-[#ecf4fe]">
    404
  </div>
);

const props: Props = {
  bottomAsset: {
    src: notFoundNode,
  },
  contentKey: PageType.NotFound,
  displayHomeButton: true,
};

export const NotFoundPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isPwaOrMobile } = useDisplayModeContext();
  const shouldRedirectToHome = isPwaOrMobile && location?.pathname?.startsWith('/employers/');

  useEffect(() => {
    if (shouldRedirectToHome) {
      navigate('/');
    }
  }, [navigate, shouldRedirectToHome]);

  return <ErrorPage {...props} />;
};

export default NotFoundPage;
