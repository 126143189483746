import React, { useCallback, useEffect } from 'react';
import { TemplateList } from './template-list';
import { CreateTemplateButton } from './create/create-template-button';
import { ButtonColor, ButtonPull, ButtonSize } from '../core/button/button';
import { EditEmailTemplateForm } from './edit/edit-email-template-form';
import {
  GenericTemplatesBackendAdapter,
  TemplateFollowup,
  TemplateType,
  TemplateContent,
  EditTemplateFormProps,
} from './types';
import { Tabs } from '../core/layout/tabs/tabs';
import { FollowupTemplateList } from './followup-template-list';
import { useTranslation } from 'react-i18next';
import { MediaBackendAdapter } from '../media-management';
import { Modal } from '../core/modal/modal';
import { EditNotificationTemplateForm } from './edit/edit-notification-template-form';
import { EditSmsTemplateForm } from './edit/edit-sms-template-form';

export type TemplateManagerProps<Content extends TemplateContent> = {
  contentCssUrl: string;
  templateType: TemplateType;
  templateName: string;
  templateTitle: string;
  onModeChange?: (mode: 'list' | 'edit') => void;
  backendAdapter: GenericTemplatesBackendAdapter<Content>;
  mediaBackendAdapter: MediaBackendAdapter | undefined;
};

type SelectedTemplate<Content extends TemplateContent> = Content &
  Pick<TemplateManagerProps<Content>, 'templateName' | 'templateTitle'>;

export const TemplateManager = <Content extends TemplateContent>({
  contentCssUrl,
  templateType,
  templateName,
  templateTitle,
  onModeChange,
  backendAdapter,
  mediaBackendAdapter,
}: TemplateManagerProps<Content>) => {
  const { loading, templates, followups, reload } = backendAdapter.useTemplates({ templateName });
  const { deleteTemplate } = backendAdapter.useDeleteTemplate({ templateName, onTemplateDeleted: reload });
  const { t } = useTranslation();

  const buildFollowupName = useCallback(
    (followup: TemplateFollowup) =>
      `${t(followup.recurring ? 'emailTemplates.followups.recurringFollowup' : 'emailTemplates.followups.followup')} (${
        followup.delayInDays
      } ${t('emailTemplates.followups.days')})`,
    [t],
  );

  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = React.useState<SelectedTemplate<Content>>();

  const selectTemplate = useCallback(
    (template: Content) => {
      const { templateName: selectedTemplateName, templateTitle: selectedTemplateTitle } =
        activeTab === 0
          ? { templateName, templateTitle }
          : {
              templateName: followups[activeTab - 1].type || followups[activeTab - 1].name || '',
              templateTitle: buildFollowupName(followups[activeTab - 1]),
            };
      setSelectedTemplate({ ...template, templateName: selectedTemplateName, templateTitle: selectedTemplateTitle });
    },
    [activeTab, templateName, templateTitle, followups, buildFollowupName],
  );

  useEffect(() => {
    if (onModeChange) {
      onModeChange(selectedTemplate ? 'edit' : 'list');
    }
  }, [selectedTemplate, onModeChange]);

  const FormComponent: React.ComponentType<EditTemplateFormProps<any>> =
    templateType === 'email'
      ? EditEmailTemplateForm
      : templateType === 'notification'
        ? EditNotificationTemplateForm
        : EditSmsTemplateForm;

  return (
    <div className="email-template-manager">
      <h3 className="u-mb-4">{templateTitle}</h3>

      {followups && followups.length > 0 ? (
        <Tabs
          showTabs
          onTabChange={setActiveTab}
          selectedTab={activeTab}
          tabsContent={[
            {
              title: t('emailTemplates.followups.initial'),
              content: (
                <>
                  <TemplateList
                    templates={templates}
                    onEditTemplate={selectTemplate}
                    onDeleteTemplate={deleteTemplate}
                  />
                  <CreateTemplateButton<Content>
                    size={ButtonSize.Small}
                    fullWidth={true}
                    color={ButtonColor.Primary}
                    pull={ButtonPull.Right}
                    templateType={templateType}
                    templateName={templateName}
                    templates={templates}
                    onTemplateUpdated={(template) => {
                      reload().then(() => {
                        selectTemplate(template);
                      });
                    }}
                    backendAdapter={backendAdapter}
                  >
                    {t('emailTemplates.addNewLocale')}
                  </CreateTemplateButton>
                </>
              ),
            },
            ...followups.map((followup, index) => ({
              title: buildFollowupName(followup),
              content: (
                <FollowupTemplateList
                  onClickTemplate={selectTemplate}
                  templateName={followup.type || followup.name || ''}
                  templateType={templateType}
                  backendAdapter={backendAdapter}
                />
              ),
            })),
          ]}
        />
      ) : templates?.length ? (
        <>
          <TemplateList templates={templates} onEditTemplate={selectTemplate} onDeleteTemplate={deleteTemplate} />
          <CreateTemplateButton<Content>
            size={ButtonSize.Small}
            fullWidth={true}
            color={ButtonColor.Primary}
            pull={ButtonPull.Right}
            templateType={templateType}
            templateName={templateName}
            templates={templates}
            backendAdapter={backendAdapter}
            onTemplateUpdated={(template) => {
              reload().then(() => {
                selectTemplate(template);
              });
            }}
            className="u-mt-3"
          >
            {t('emailTemplates.addNewLocale')}
          </CreateTemplateButton>
        </>
      ) : !loading ? (
        <p className="u-pull-center">{t('emailTemplates.noTemplates')}</p>
      ) : (
        <p className="u-pull-center">{t('emailTemplates.loadingTemplates')}</p>
      )}

      {selectedTemplate ? (
        <Modal
          className="modal modal-email-template-editor"
          open={true}
          onClose={() => setSelectedTemplate(undefined)}
          size="large"
          onInteractOutside={(e: any) => {
            e.preventDefault();
          }}
          modal={false}
          overlay={true}
        >
          <FormComponent
            contentCssUrl={contentCssUrl}
            templateType={templateType}
            templateName={selectedTemplate.templateName}
            title={selectedTemplate.templateTitle}
            initial={selectedTemplate}
            backendAdapter={backendAdapter}
            mediaBackendAdapter={mediaBackendAdapter}
            onTemplateUpdated={() => {
              reload().then(() => {
                setSelectedTemplate(undefined);
              });
            }}
          />
        </Modal>
      ) : null}
    </div>
  );
};
