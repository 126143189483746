// toolbar string format: 'undo redo | blocks fontsize | bold italic underline table alignleft aligncenter alignright | numlist bullist | backcolor forecolor removeformat | code',

export class WysiwygToolbarBuilder {
  protected segments: string[] = [];

  private constructor(toolbar = '') {
    this.segments = toolbar.split('|');
  }

  static from(toolbar: string): WysiwygToolbarBuilder {
    return new WysiwygToolbarBuilder(toolbar);
  }

  static new() {
    return new WysiwygToolbarBuilder();
  }

  build() {
    return this.segments.join(' | ');
  }

  with(segment: string) {
    this.segments.push(segment);
    return this;
  }

  withUndo() {
    return this.with('undo');
  }

  withUndoRedo() {
    return this.with('undo redo');
  }

  withBlocks() {
    return this.with('blocks');
  }

  withFontSize() {
    return this.with('fontsize');
  }

  withTextStyle() {
    return this.with('bold italic underline striketrough');
  }

  withTable() {
    return this.with('table');
  }

  withQuote() {
    return this.with('blockquote');
  }

  withAlignment() {
    return this.with('alignleft aligncenter alignright');
  }

  withLists() {
    return this.with('numlist bullist');
  }

  withCode() {
    return this.with('code');
  }
}
