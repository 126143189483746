import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { CookieConsent } from './components/CookieConsent/CookieConsent';
import LogInSignUpModal from './components/LogInSignUpModal/LogInSignUpModalComponent';
import { WebAuthnHandler } from './components/WebAuthn/WebAuthnHandler';
import { useDisplayModeContext } from './context/DisplayModeContext';
import './styles/main.scss';
import { RequestNotificationPermissionModal } from './components/notifications/request-notification-permission-modal';

const ReactQueryDevtools = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

const App = () => {
  const { displayMode } = useDisplayModeContext();
  useEffect(() => {
    const invalid = (e) => {
      e.preventDefault();
    };

    document.addEventListener('invalid', invalid, true);

    return () => {
      document.removeEventListener('invalid', invalid, true);
    };
  }, []);

  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <div className="app-wrapper">
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtools initialIsOpen={true} />
        </React.Suspense>
      )}
      <Outlet />

      <LogInSignUpModal />
      {aliroConfig?.employerPrefs?.useNotifications ? <RequestNotificationPermissionModal /> : null}
      {displayMode !== 'pwa' ? <CookieConsent /> : null}
      <WebAuthnHandler />
    </div>
  );
};

export default App;
