import { ButtonProps } from '../../core/button/button';
import { ModalButton } from '../../core/modal/modal-button';
import { CreateTemplateFormProps, TemplateContent } from '../types';
import { CreateEmailTemplateForm } from './create-email-template-form';
import { CreateNotificationTemplateForm } from './create-notification-template-form';
import { CreateSmsTemplateForm } from './create-sms-template-form';

export type CreateTemplateButtonProps<Content extends TemplateContent> = Omit<ButtonProps, 'onClick'> &
  CreateTemplateFormProps<Content>;

export const CreateTemplateButton = <Content extends TemplateContent>({
  templateType,
  templateName,
  templates,
  backendAdapter,
  onTemplateUpdated,
  ...buttonProps
}: CreateTemplateButtonProps<Content>) => {
  const FormComponent: React.ComponentType<CreateTemplateFormProps<any>> =
    templateType === 'email'
      ? CreateEmailTemplateForm
      : templateType === 'notification'
        ? CreateNotificationTemplateForm
        : CreateSmsTemplateForm;

  return (
    <ModalButton
      body={(close) => (
        <FormComponent
          templateType={templateType}
          templateName={templateName}
          templates={templates}
          backendAdapter={backendAdapter}
          onTemplateUpdated={(...args) => {
            close();
            onTemplateUpdated?.(...args);
          }}
        />
      )}
      {...buttonProps}
    ></ModalButton>
  );
};
