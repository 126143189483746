import {
  Box,
  Button,
  ButtonColor,
  ButtonSize,
  FormRadioInput,
  FormRadioInputOption,
  Icon,
} from '@alirosoftware/web-components';
import React, { useMemo } from 'react';
import axios, { AxiosError } from 'axios';
import { startAuthentication } from '@simplewebauthn/browser';
import { getLocalstorageWebauthnCredentialId, postVerifyLogin } from '../../../helpers/webauthn';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { QUERY_KEY_MFA_SESSION, useMfaSession } from '../../Settings/Sections/mfa/useMfaSession';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MdFingerprint, MdOutlineEmail, MdOutlineSms } from 'react-icons/md';
import { EmailVerificationModalContent } from './EmailVerificationModalContent';
import { SmsVerificationModalContent } from './SmsVerificationModalContent';
import { AuthenticatorVerificationModalContent } from './AuthenticatorVerificationModalContent';
import { KeyIconSvg } from '../../Shared/raw-icons';
import { EmailIcon, SmsIcon } from '../../Shared/icons';
import { useTranslation } from 'react-i18next';

export type MfaLoginProps = {
  token: string;
  onFail: (error?: string) => void;
  onSuccess: (data) => void;
};

enum MfaMethod {
  Email = 'email',
  Sms = 'sms',
  Biometric = 'biometric',
  Authenticator = 'authenticator',
}

type MfaLoginFormValues = {
  method: MfaMethod;
};

export const MfaLogin = ({ token, onFail, onSuccess }: MfaLoginProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [selectedMethod, setSelectedMethod] = React.useState<MfaMethod | null>(null);
  const [loggingIn, setLoggingIn] = React.useState(false);

  const { data: session } = useMfaSession(token, {
    useErrorBoundary: (error: AxiosError<{ message?: string }>) => {
      onFail(error?.response?.data?.message || 'Invalid token');
      return false;
    },
  });

  const [verificationError, setVerificationError] = React.useState<string>();
  const { mutate: sendEmail, isLoading: sendingEmail } = useMutation({
    mutationFn: () => axios.post(`/api/v2/auth/mfa/${token}/send-verification-email`).then((r) => r.data),
    onSuccess: () => {
      setSelectedMethod(MfaMethod.Email);
    },
    onError: (e: AxiosError<{ message?: string }>) => {
      setVerificationError(e?.response?.data?.message || 'An error occurred');
      queryClient.invalidateQueries([QUERY_KEY_MFA_SESSION]);
    },
  });
  const { mutate: sendSms, isLoading: sendingSms } = useMutation({
    mutationFn: () => axios.post(`/api/v2/auth/mfa/${token}/send-verification-sms`).then((r) => r.data),
    onSuccess: () => {
      setSelectedMethod(MfaMethod.Sms);
    },
  });

  const tryLoginWebAuthn = () => {
    const authOptions = session?.availableMethods.webauthn?.authOptions;
    if (!authOptions) {
      return;
    }

    setLoggingIn(true);

    startAuthentication(authOptions)
      .then((loginResponse) => {
        postVerifyLogin(session.availableMethods.email, loginResponse)
          .then((data) => {
            if (data?.success) {
              onSuccess(data);
              return;
            }
          })
          .catch((err) => {
            console.log('Auto login with webauthn failed', err);
          })
          .finally(() => {
            setLoggingIn(false);
          });
      })
      .catch((err) => {
        console.log('Auto login with webauthn failed', err);
        setLoggingIn(false);
      });
  };

  const availableLoginMethods: Partial<Record<MfaMethod, FormRadioInputOption>> = useMemo(() => {
    const methods: Partial<Record<MfaMethod, FormRadioInputOption>> = {};

    if (session?.availableMethods?.email) {
      methods[MfaMethod.Email] = {
        label: t('mfaSettings.sendCodeByEmail'),
        icon: <Icon icon={EmailIcon} className="text-blue-800" />,
        value: MfaMethod.Email,
      };
    }

    if (session?.availableMethods?.sms) {
      methods[MfaMethod.Sms] = {
        label: t('mfaSettings.sendCodeBySms'),
        icon: <Icon icon={SmsIcon} className="text-blue-800" />,
        value: MfaMethod.Sms,
      };
    }

    if (session?.availableMethods?.authenticator) {
      methods[MfaMethod.Authenticator] = {
        label: t('mfaSettings.googleAuthenticator'),
        icon: <KeyIconSvg />,
        value: MfaMethod.Authenticator,
      };
    }

    if (session?.availableMethods?.webauthn?.authOptions) {
      const credentialId = getLocalstorageWebauthnCredentialId();
      if (credentialId && session?.availableMethods?.webauthn?.credentialIds?.includes(credentialId)) {
        methods[MfaMethod.Biometric] = {
          label: t('mfaSettings.biometric'),
          icon: <Icon icon={MdFingerprint} className="text-blue-800" />,
          value: MfaMethod.Biometric,
        };
      }
    }

    return methods;
  }, [session?.availableMethods]);

  if (session && selectedMethod === MfaMethod.Email) {
    return <EmailVerificationModalContent session={session} onCancel={() => setSelectedMethod(null)} />;
  } else if (session && selectedMethod === MfaMethod.Sms) {
    return <SmsVerificationModalContent session={session} onCancel={() => setSelectedMethod(null)} />;
  } else if (session && selectedMethod === MfaMethod.Authenticator) {
    return <AuthenticatorVerificationModalContent session={session} onCancel={() => setSelectedMethod(null)} />;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className="mfa-login">
      <h3 className="u-align-self-flex-start u-mt-0">{t('mfaSettings.verifyIdentity')}</h3>
      <p className="u-align-self-flex-start">{t('mfaSettings.mfaRequired')}</p>

      {Object.keys(availableLoginMethods).length ? (
        <Formik<MfaLoginFormValues>
          initialValues={{
            method: availableLoginMethods[MfaMethod.Biometric] ? MfaMethod.Biometric : MfaMethod.Email,
          }}
          validationSchema={Yup.object().shape({
            method: Yup.string().required().oneOf(Object.keys(availableLoginMethods)),
          })}
          validateOnChange={true}
          onSubmit={({ method }) => {
            if (method === MfaMethod.Email) {
              sendEmail();
            } else if (method === MfaMethod.Sms) {
              sendSms();
            } else if (method === MfaMethod.Biometric) {
              tryLoginWebAuthn();
            } else if (method === MfaMethod.Authenticator) {
              setSelectedMethod(MfaMethod.Authenticator);
            }
          }}
        >
          {({ handleSubmit, submitForm, isValid, values }) => (
            <form onSubmit={handleSubmit} className="w-full flex flex-col u-align-items-center">
              <FormRadioInput
                containerClassName="radio-group mfa-login-radio-group w-full"
                options={Object.values(availableLoginMethods)}
                name="method"
              />
              {verificationError ? <p className="error-message">{verificationError}</p> : null}
              <Button
                type="button"
                color={ButtonColor.Primary}
                onClick={submitForm}
                size={ButtonSize.Small}
                loading={sendingEmail || sendingSms || loggingIn}
                disabled={!isValid}
              >
                {values.method === MfaMethod.Biometric
                  ? t('mfaSettings.logIn')
                  : values.method === MfaMethod.Authenticator
                    ? t('mfaSettings.nextStep')
                    : t('mfaSettings.sendCode')}
              </Button>
            </form>
          )}
        </Formik>
      ) : null}
    </Box>
  );
};
