import { Button, ButtonColor, ButtonPull, ButtonSize, FormOtpInput, Modal } from '@alirosoftware/web-components';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

export type VerificationModalProps = {
  onSubmit: (code: string) => Promise<void>;
  onCancel: () => void;
  title: string | React.ReactNode;
  body: React.ReactNode;
  error?: string;
  submitText: string;
} & (
  | {
      onResend: () => Promise<void>;
      resendText: string;
      resentText: string;
    }
  | {
      onResend?: never;
      resendText?: never;
      resentText?: never;
    }
);

export const VerificationModalContent = ({
  onSubmit,
  onCancel,
  title,
  body,
  error: errorProp,
  submitText,
  onResend,
  resendText,
  resentText,
}: VerificationModalProps) => {
  const [error, setError] = React.useState<string | null>(null);
  const [resent, setResent] = React.useState(false);
  return (
    <div className="flex flex-col justify-center items-center u-pl-4 u-pr-4">
      <h3 className="text-24px text-offblack font-bold u-align-self-flex-start u-mt-0 u-mb-2">{title}</h3>
      {body}
      {onResend ? (
        <a
          href="#"
          className="text-center text-lg text-blue-800 underline u-mb-2"
          onClick={(e) => {
            e.preventDefault();
            onResend()
              .then(() => {
                setResent(true);
              })
              .catch((e) => {
                setError(e?.message || 'An error occurred');
              });
          }}
        >
          {resent ? resentText : resendText}
        </a>
      ) : null}
      <Formik
        initialValues={{ code: '' }}
        onSubmit={(values) => {
          return onSubmit(values.code).catch((e) => {
            setError(e?.message || 'An error occurred');
          });
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(),
        })}
      >
        {({ handleSubmit, submitForm }) => (
          <form onSubmit={handleSubmit}>
            {errorProp || error ? <p className="error-message">{errorProp || error}</p> : null}
            <FormOtpInput className="u-mt-5 u-mb-5" name="code" codeLength={6} />
            <Button
              className="u-mt-4"
              onClick={submitForm}
              color={ButtonColor.Primary}
              size={ButtonSize.Small}
              pull={ButtonPull.Center}
            >
              {submitText}
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export const VerificationModal = (props: VerificationModalProps) => {
  return (
    <Modal
      open={true}
      onClose={() => {
        props?.onCancel?.();
      }}
      size="default"
    >
      <VerificationModalContent {...props} />
    </Modal>
  );
};
