import { ReactNode } from 'react';

export type PipelineCard = {
  color: PipelineCardColor;
  content: Array<ReactNode> | ReactNode;
  draggable?: boolean;
  id: string;
};

export enum PipelineCardColor {
  Gray = 'gray',
  Green = 'green',
  Orange = 'orange',
  Pink = 'pink',
  Yellow = 'yellow',
}

export type PipelineCardProps = {
  children?: Array<ReactNode> | ReactNode;
  color: PipelineCardColor;
  draggable?: boolean;
  id: string;
  onDragEnd: (previousPipelineId: string, nextPipelineId: string, cardId: string) => Promise<void> | void;
  onClick?: (pipelineId: string, cardId: string) => Promise<void> | void;
  onRemove?: (pipelineId: string, cardId: string) => Promise<void> | void;
  pipelineId: string;
  type?: string;
};

export type PipelineCardDropResult = {
  receivingPipelineId: string;
};

export type PipelineHeaderProps = {
  subtitle: string;
  title: string;
};

export type PipelineProps = {
  cards: Array<PipelineCard>;
  disabled?: boolean;
  id: string;
  onCardClick?: (pipelineId: string, cardId: string) => Promise<void> | void;
  onCardDragEnd: (previousPipelineId: string, nextPipelineId: string, cardId: string) => Promise<void> | void;
  onCardRemove?: (pipelineId: string, cardId: string) => Promise<void> | void;
  type?: string;
} & PipelineHeaderProps;
