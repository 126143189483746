import { useCallback } from 'react';
import axios from 'axios';
import { useLoader } from '../../../hooks/useLoader';
import { BuildEmailTemplatePreviewResponse, UseBuildEmailTemplatePreviewResult } from '../types';
import { t } from 'i18next';

export type UseBuildEmailTemplatePreviewOpts = {
  apiBaseUrl: string;
  employerId: string;
  emailType: string;
};

export const useBuildEmailTemplatePreview = ({
  apiBaseUrl,
  employerId,
  emailType,
}: UseBuildEmailTemplatePreviewOpts): UseBuildEmailTemplatePreviewResult => {
  const { loading, error, run, setError } = useLoader({ cancelOnUnmount: false });

  const buildPreview = useCallback(
    (template: string, type: 'body' | 'subject' = 'body'): Promise<string | null> => {
      return run(
        axios
          .post<BuildEmailTemplatePreviewResponse>(
            `/api/v2/employer/${employerId}/templates/email/${emailType}/preview`,
            { template, type: type === 'body' ? 'html' : 'value' },
            {
              baseURL: apiBaseUrl,
            },
          )
          .then((result) => {
            setError(undefined);
            if (!result?.data?.success) {
              setError('Error loading preview from API');
              // Cancelled
              return null;
            }

            return result.data.preview;
          })
          .catch((e) => {
            if (e.response?.data.translateKey) {
              setError(t(e.response.data.translateKey) + e.response.data.errorData?.toString() || '');
            } else {
              setError(e.response?.data?.message || e?.message || e?.toString() || 'Unknown Error');
            }
            return null;
          }),
      );
    },
    [apiBaseUrl, employerId, emailType, run, setError],
  );

  return {
    error,
    loading,
    buildPreview,
  };
};
