import React, { useEffect } from 'react';
import localAuth from '../../authentication/local-auth';

export type IncorrectHostRedirectProps = {
  redirectTo: string;
};

let redirectTimeout: NodeJS.Timeout;

export const IncorrectHostRedirect = ({ redirectTo }: IncorrectHostRedirectProps) => {
  useEffect(() => {
    if (redirectTimeout) {
      clearTimeout(redirectTimeout);
    }

    if (redirectTo) {
      localAuth.clearLocalAuth();
      redirectTimeout = setTimeout(() => {
        window.location.href = `https://${redirectTo}`;
      }, 1000);
    }

    return () => {
      if (redirectTimeout) {
        clearTimeout(redirectTimeout);
      }
    };
  }, [redirectTo]);

  return null;
};
