import { User } from '@alirosoftware/core-types';
import SESSION_STORAGE_KEYS from '../constants/SessionStorageConstants';

const clearLocalAuth = () => {
  sessionStorage.removeItem('notLoggedIn');
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.USER.DATA);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.EMPLOYER.DATA);
};

const storeLocalUser = (user) => {
  sessionStorage.setItem(SESSION_STORAGE_KEYS.USER.DATA, JSON.stringify(user));
};

const storeLocalEmployer = (employer) => {
  sessionStorage.setItem(SESSION_STORAGE_KEYS.EMPLOYER.DATA, JSON.stringify(employer));
};

const getLocalUser = () => {
  const userData = sessionStorage.getItem(SESSION_STORAGE_KEYS.USER.DATA);
  return JSON.parse(userData);
};

const getLocalEmployer = () => {
  const employerData = sessionStorage.getItem(SESSION_STORAGE_KEYS.EMPLOYER.DATA);
  return JSON.parse(employerData);
};

export default {
  clearLocalAuth,
  storeLocalUser,
  storeLocalEmployer,
  getLocalUser,
  getLocalEmployer,
};
