import { Box, Button, ButtonColor, Modal } from '@alirosoftware/web-components';
import React from 'react';
import { useAuthContext } from '../../context/auth-context';
import { useLogout } from '../../hooks/useLogout';

export type IncorrectHostModalProps = {
  redirectTo: string;
};

export const IncorrectHostModal = ({ redirectTo }: IncorrectHostModalProps) => {
  const authContext = useAuthContext();
  const { logout } = useLogout();
  const employerName = authContext.employer?.data?.org || 'Aliro';

  const imgSrc = 'https://images.onaliro.com/client/logo_aliro_v02.png';

  return (
    <Box className="min-vh-100 min-vw-100 bg-white">
      <header className="layout__header u-full-width">
        <Box display="flex" flexDirection="row" shadow={1} px={4} className="header">
          <Box display="flex" alignItems="center" className="u-full-height">
            {imgSrc ? <img className="header-logo" src={imgSrc} alt="logo" /> : null}
          </Box>
        </Box>
      </header>
      <Modal open canClose={false} showCloseIcon={false} size="small">
        <Box display="flex" flexDirection="column" m={2}>
          <h2>Oops, you're in the wrong place!</h2>
          <p>Your account is with {employerName}, hang on, we'll help you get there...</p>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button color={ButtonColor.Secondary} onClick={logout} className="mr-2">
              Logout
            </Button>
            <a href={`https://${redirectTo}`} target="_blank" rel="noreferrer">
              <Button color={ButtonColor.Primary}>Go to {employerName}</Button>
            </a>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
