import { useState, useEffect } from 'react';

export const useDelayedValue = (value: boolean, delayTime: number) => {
  const [delayedValue, setDelayedValue] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedValue(value);
    }, delayTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delayTime]);

  return delayedValue;
};
