import { createPortal } from 'react-dom';
import CookieConsentBase from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import useAppContext from '../../context/app-context';

export const CookieConsent = () => {
  const { config } = useAppContext();
  const { t } = useTranslation();
  return createPortal(
    <CookieConsentBase
      location="bottom"
      buttonText={t('okGotIt')}
      cookieName="aliro_consent"
      buttonStyle={{ background: '#C2C2C2', verticalAlign: 'top' }}
      style={{ transform: 'translateY(0)', pointerEvents: 'auto' }}
      extraCookieOptions={{
        // @ts-ignore
        domain: `.${config.platformDomain}`,
        secure: process.env.NODE_ENV === 'production',
      }}
    >
      <div className="cookie-consent">
        {t('cookieConsent')}
        &nbsp;
        <a href="/privacy-policy">{t('privacyPolicy')}</a>
        &nbsp;
        {t('and')}
        &nbsp;
        <a href="/terms-of-use">{t('termsOfService')}</a>.
      </div>
    </CookieConsentBase>,
    document.body,
  );
};
