import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import localAuth from '../../authentication/local-auth';
import { MeDto } from '@alirosoftware/core-types/client';

type GetMeResponse = {
  employerData?: any;
  parentEmployerData?: any;
  success: boolean;
  userData?: MeDto;
};

type GetMeData = Partial<Omit<GetMeResponse, 'success'>> | null;

export const apiMe = (selectedEmployerId?: string) => {
  const url = selectedEmployerId ? `/api/me?selectedEmployerId=${selectedEmployerId}` : '/api/me';
  const { data, ...rest } = useQuery<GetMeData>({
    queryKey: ['auth', 'me'],
    queryFn: async () => {
      const response: AxiosResponse<GetMeResponse> = await axios.get(url);
      if (response.status === 200 && response.data.success) {
        // logged in, valid session
        sessionStorage.removeItem('notLoggedIn');
        localAuth.clearLocalAuth();

        const userData = response.data.userData;
        localAuth.storeLocalUser(userData);

        const employerData = response.data.employerData;
        if (employerData) {
          localAuth.storeLocalEmployer(employerData);
        }

        const parentEmployerData = response.data.parentEmployerData;

        return { employerData, parentEmployerData, userData };
      } else {
        // not logged in
        localAuth.clearLocalAuth();
        sessionStorage.setItem('notLoggedIn', 'true');
        return {
          userData: null,
          employerData: null,
        };
      }
    },
    staleTime: 1000,
    initialData: () => {
      const userData = localAuth.getLocalUser();
      const employerData = localAuth.getLocalEmployer();
      if (userData) {
        return {
          userData,
          employerData,
        };
      }
      return null;
    },
    onError: (err) => {
      localAuth.clearLocalAuth();
      sessionStorage.setItem('notLoggedIn', 'true');
    },
    retry: false, // A non-logged-in user will get an error, but that is okay.
  });

  return {
    me: data,
    data,
    ...rest,
  };
};

export default apiMe;
