import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useLoader } from '../../../hooks/useLoader';
import { GetTemplatesResponse, TemplateFollowup, UseTemplatesResult, TemplateContent, TemplateType } from '../types';

export type UseTemplatesProps = {
  apiBaseUrl: string;
  employerId: string;
  templateType: TemplateType;
  templateName: string;
};

export const useTemplates = <Content extends TemplateContent>({
  apiBaseUrl,
  employerId,
  templateType,
  templateName,
}: UseTemplatesProps): UseTemplatesResult<Content> => {
  const { loaded, loading, error, cancelLoading, run } = useLoader({ cancelOnUnmount: true });

  const [templates, setTemplates] = useState<Content[]>([]);
  const [followups, setFollowups] = useState<TemplateFollowup[]>([]);

  const loadData = useCallback(
    async (type: string) => {
      setFollowups([]);

      cancelLoading();
      await run(
        axios.get<GetTemplatesResponse<Content>>(`/api/v2/employer/${employerId}/templates/${templateType}/${type}`, {
          baseURL: apiBaseUrl,
        }),
      )?.then((result) => {
        if (!result) {
          // Cancelled
          return;
        }

        if (result.data?.success) {
          setTemplates(result.data.templates);
          setFollowups(result.data.followup);
        } else {
          throw new Error(result.data?.error || 'Unknown error');
        }
      });
    },
    [apiBaseUrl, employerId, run, cancelLoading],
  );

  const reload = useCallback(() => {
    return loadData(templateName);
  }, [loadData, templateName]);

  useEffect(() => {
    loadData(templateName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateName]);

  return {
    error,
    loaded,
    loading,
    reload,
    templates,
    followups,
  };
};
