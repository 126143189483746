import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { useAuthContext } from './auth-context/auth-context';
import { useCookieValue } from '../hooks/cookie-value-query';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { useRegisterForPushNotifications } from '../hooks/firebase';

export type RequestNotificationPermissionContextValue = {
  isOpen: boolean;
  trigger?: string;
  open: (trigger: string) => void;
  dismiss: () => void;
  shouldPromptForPermission: boolean;
  enable: () => void;
};

export const RequestNotificationPermissionContext = createContext<RequestNotificationPermissionContextValue>({
  isOpen: false,
  open: () => {},
  dismiss: () => {},
  shouldPromptForPermission: false,
  enable: () => {},
});

export const RequestNotificationPermissionProvider = ({ children }: PropsWithChildren) => {
  const { value: hasPrompted, set: setHasPrompted } = useCookieValue<'1'>('notificationPermissionPrompted', 7);
  const [isOpen, setOpen] = useState(false);
  const [trigger, setTrigger] = useState<string>();
  const { user, refreshUserData } = useAuthContext();
  const queryClient = useQueryClient();

  const shouldPromptForPermission = useMemo(() => {
    return (
      'Notification' in window &&
      window.Notification.permission !== 'denied' &&
      !!aliroConfig?.employerPrefs?.useNotifications &&
      user?.isLoggedIn &&
      !user?.notificationsEnabled &&
      hasPrompted !== '1'
    );
  }, [user?.isLoggedIn, user?.notificationsEnabled, hasPrompted, window.Notification?.permission]);

  useRegisterForPushNotifications({
    userId: user.id,
    enabled: aliroConfig.employerPrefs?.useNotifications && user?.notificationsEnabled,
    autoRegister: true,
  });

  const open = useCallback(
    (trigger: string) => {
      if (shouldPromptForPermission) {
        setTrigger(trigger);
        setOpen(true);
        setHasPrompted('1');
      }
    },
    [shouldPromptForPermission],
  );

  const logResult = useCallback(
    (result: 'granted' | 'denied' | 'dismissed') => {
      axios.post('/api/v2/behaviour/notification-permission', { result, trigger }).catch((e) => {
        console.error('Failed to log notification permission result', e);
      });
    },
    [trigger],
  );

  const dismiss = useCallback(() => {
    logResult('dismissed');
    setOpen(false);
  }, [trigger]);

  const enable = useCallback(() => {
    // mutationKey: ['user', 'notification-prefs'],
    setOpen(false);

    // Request notification permission - only if it hasn't been denied
    if ('Notification' in window && window.Notification.permission !== 'denied') {
      window.Notification.requestPermission()
        .then((result) => {
          if (result === 'granted') {
            logResult('granted');
            axios
              .patch(`/api/v2/user/preferences/notification`, {
                preferences: [{ preference: 'active', enabled: true }],
              })
              .then(() => {
                refreshUserData();
                queryClient.invalidateQueries(['user', 'notification-prefs']);
              });
          } else if (result === 'denied') {
            logResult('denied');
          }
        })
        .catch((e) => {
          console.error('Error requesting notification permission', e);
        });
    }
  }, [logResult]);

  return (
    <RequestNotificationPermissionContext.Provider
      value={{
        isOpen,
        trigger,
        open,
        dismiss,
        shouldPromptForPermission,
        enable,
      }}
    >
      {children}
    </RequestNotificationPermissionContext.Provider>
  );
};

export const useRequestNotificationPermissionContext = () => useContext(RequestNotificationPermissionContext);
