import classnames from 'classnames';
import { ReactElement } from 'react';
import { useDrop } from 'react-dnd';
import { PipelineCard } from './pipeline-card';
import { PipelineHeader } from './pipeline-header';
import { PipelineCard as Card, PipelineProps } from './types';

export const Pipeline = ({
  cards,
  disabled,
  id,
  onCardClick,
  onCardDragEnd,
  onCardRemove,
  subtitle,
  title,
  type = 'CARD',
}: PipelineProps): ReactElement => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: type,
    canDrop: () => !disabled,
    collect: (monitor) => ({
      isOver: !disabled && monitor.isOver(),
      canDrop: !disabled && monitor.canDrop(),
    }),
    drop: (): { receivingPipelineId: string } => ({
      receivingPipelineId: id,
    }),
  }));

  return (
    <div className="pipeline">
      {<PipelineHeader subtitle={subtitle} title={title} />}
      <div className={classnames('pipeline__body', { 'pipeline__body--active': canDrop && isOver })} ref={drop}>
        {cards.map((card: Card) => (
          <PipelineCard
            color={card.color}
            draggable={card.draggable && !disabled}
            id={card.id}
            key={card.id}
            onClick={onCardClick}
            onDragEnd={onCardDragEnd}
            onRemove={onCardRemove}
            pipelineId={id}
            type={type}
          >
            {card.content}
          </PipelineCard>
        ))}
      </div>
    </div>
  );
};

export default Pipeline;
