import { Button, ButtonColor } from '@alirosoftware/web-components';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { useLoginSignupContext } from '../../context/LoginSignupModalContext';
import { Asset, Props, XPosition } from './types';

export const ErrorPage = (props: Props): ReactElement => {
  const error = useRouteError() as any;

  if (error) {
    console.error('ErrorPage', error.message || error);
  }

  return <ErrorPagePresentation {...props} />;
};

export const ErrorPagePresentation = ({
  bottomAsset,
  contentKey,
  displayHomeButton,
  displayLoginButton,
  topAsset,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { openLoginSignupModal } = useLoginSignupContext();

  // Having to do this weird conversion because for some reason the TS compiler thinks that bottomAsset is of type never. *shrug*
  const bottomAssetX = bottomAsset as Asset<XPosition.Left>;

  useEffect((): void => {
    document.title = 'Aliro';
  }, []);

  return (
    <div className="overflow-hidden">
      <div className="flex justify-center h-screen bg-[#fbfbfb] w-11/12 mx-auto">
        <div className="relative isolate min-w-0 mt-[88px] w-[512px] sm:mt-[176px]">
          {topAsset &&
            (topAsset.sizeAndPosition ? (
              <div className="absolute" style={{ ...topAsset.sizeAndPosition }}>
                {topAsset.src}
              </div>
            ) : (
              topAsset.src
            ))}
          <div className="relative z-10 h-fit bg-white shadow rounded-[4px] py-[32px] px-[36px] flex flex-col">
            <img
              alt="Aliro logo"
              className="mx-auto block w-max h-[32px]"
              src="https://images.onaliro.com/client/logo_aliro_v02.png"
            />
            <h1 className="mx-auto w-fit text-center mt-[32px] text-[20px]">{t(`errorPage.${contentKey}.title`)}</h1>
            <p className="mx-auto mb-0 w-fit text-center mt-[24px]">{t(`errorPage.${contentKey}.message`)}</p>
            {displayHomeButton && (
              <a className="button button-primary mx-auto block !w-fit mt-[48px] mb-0" href="/">
                {t('home')}
              </a>
            )}
            {displayLoginButton && (
              <Button
                className="mx-auto block !w-fit mt-[48px] mb-0"
                color={ButtonColor.Primary}
                onClick={() => openLoginSignupModal()}
                type="button"
              >
                {t('logIn')}
              </Button>
            )}
          </div>
          {bottomAssetX &&
            (bottomAssetX.sizeAndPosition ? (
              <div className="absolute" style={{ ...bottomAssetX.sizeAndPosition }}>
                {bottomAssetX.src}
              </div>
            ) : (
              bottomAssetX.src
            ))}
        </div>
      </div>
    </div>
  );
};
