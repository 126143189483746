import React from 'react';
import { VerificationModalContent } from '../../Settings/Sections/mfa/VerificationModal';
import { MfaCodeInput, MfaSessionDto } from '@alirosoftware/nest-mfa/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useAfterLogin } from '../../../hooks/useAfterLogin';
import { useTranslation } from 'react-i18next';

export type AuthenticatorVerificationModalContentProps = { session: MfaSessionDto; onCancel: () => void };

export const AuthenticatorVerificationModalContent = ({
  session,
  onCancel,
}: AuthenticatorVerificationModalContentProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { afterLogin } = useAfterLogin();
  const [verificationError, setVerificationError] = React.useState<string>();

  const { mutate: verifyAuthenticator, isLoading: verifyingAuthenticator } = useMutation<any, any, MfaCodeInput>({
    mutationFn: (data) =>
      axios.post(`/api/v2/auth/mfa/${session.token}/verify-authenticator`, data).then((r) => r.data),
    onSuccess: (detail) => {
      afterLogin({ detail, skipWebAuthn: true });
    },
    onError: (e: AxiosError<{ message?: string }>) => {
      setVerificationError(e?.response?.data?.message || 'An error occurred');
    },
  });

  const onSubmitVerificationCode = async (code: string) => {
    try {
      verifyAuthenticator({ code });
    } catch (e) {
      setVerificationError(e?.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <VerificationModalContent
      title={t('mfaSettings.verifyIdentity')}
      body={<p className="text-lg text-offblack u-mt-2 u-mb-0">{t('mfaSettings.enterGoogleAuthenticatorCode')}</p>}
      error={verificationError}
      submitText={t('mfaSettings.verifyIdentity')}
      onSubmit={onSubmitVerificationCode}
      onCancel={onCancel}
    />
  );
};
