import { useTranslation } from 'react-i18next';
import SocialButton from '../SocialButton/SocialButtonComponent';
import BUTTON_CONSTANTS from '../../constants/ButtonConstants';
import OrDivider from './OrDivider';
import EmailLoginForm from './EmailLoginForm';
import { FC } from 'react';
import { Button, ButtonColor, ButtonVariant } from '@alirosoftware/web-components';

type Props = {
  isCoreSite: boolean;
  onLogin?: (values: Record<'email' | 'password', string>) => void | Promise<void>;
  changeTab?: (tab: string) => void;
  getToken?: () => Promise<string>;
  onMfaError?: (token: string) => void;
};

const SignInOptions: FC<Props> = ({ getToken, changeTab, isCoreSite, onMfaError }) => {
  const { t } = useTranslation();
  const GACategory = 'Login Signup Modal';

  return (
    <div>
      {isCoreSite && (
        <div>
          <div className="font-medium">{t('continueWith')}</div>
          <div className="flex flex-row justify-around mt-[16px]">
            <SocialButton
              ga={{ category: GACategory, action: 'Login LinkedIn' }}
              network={BUTTON_CONSTANTS.NETWORK.LINKEDIN}
              type={BUTTON_CONSTANTS.TYPE.SIGN_IN}
              asLogo
            />
            <SocialButton
              ga={{ category: GACategory, action: 'Login Facebook' }}
              network={BUTTON_CONSTANTS.NETWORK.FACEBOOK}
              type={BUTTON_CONSTANTS.TYPE.SIGN_IN}
              asLogo
            />
            <SocialButton
              ga={{ category: GACategory, action: 'Login Apple' }}
              network={BUTTON_CONSTANTS.NETWORK.APPLE}
              type={BUTTON_CONSTANTS.TYPE.SIGN_IN}
              asLogo
            />
          </div>
          <OrDivider />
        </div>
      )}
      <EmailLoginForm
        getToken={getToken}
        onForgotPassword={() => changeTab('FORGOT_PASSWORD')}
        onMfaError={onMfaError}
      />
      <div className="w-full text-center my-[12px]">
        <Button
          variant={ButtonVariant.Text}
          color={ButtonColor.Blue}
          className="!text-16 !underline !font-normal"
          onClick={() => changeTab('SIGN_UP')}
        >
          {t('createAnAccount')}
        </Button>
      </div>
    </div>
  );
};

export default SignInOptions;
