import classnames from 'classnames';
import { ForwardedRef, InputHTMLAttributes, ReactElement, forwardRef } from 'react';

type Props = {
  /**
   * We have to override the existing unwanted styles with a high specificity selector.
   * This means additional selector properties outside the current specificity need to be applied as '!important'.
   * Tailwind CSS classes need to by applied with the '!' prefix.
   */
  className?: string;
  labelText: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

export const StyledRadio = forwardRef<HTMLInputElement, Props>(
  ({ className, id, labelText, ...rest }: Props, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
    const cssClasses = classnames('styled-radio', className);

    return (
      <label className={cssClasses} htmlFor={id}>
        <input {...rest} id={id} ref={ref} type="radio" />
        <span>{labelText}</span>
      </label>
    );
  },
);

export default StyledRadio;
