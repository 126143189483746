import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BUTTON_CONSTANTS from '../../constants/ButtonConstants';
import SocialButton from '../SocialButton/SocialButtonComponent';
import OrDivider from './OrDivider';
import SignUpForm from './SignUpForm';

type Props = {
  changeTab: (tab: string) => void;
  isCoreSite: boolean;
  close: () => void;
};

const SignUpOptions: FC<Props> = ({ changeTab, isCoreSite, close }) => {
  const { t } = useTranslation();
  const GACategory = 'Login Signup Modal';

  return (
    <div>
      {isCoreSite && (
        <div>
          <div className="font-medium">{t('continueWith')}</div>
          <div className="flex flex-row justify-around mt-[16px]">
            <SocialButton
              ga={{ category: GACategory, action: 'Login LinkedIn' }}
              network={BUTTON_CONSTANTS.NETWORK.LINKEDIN}
              type={BUTTON_CONSTANTS.TYPE.SIGN_IN}
              asLogo
            />
            <SocialButton
              ga={{ category: GACategory, action: 'Login Facebook' }}
              network={BUTTON_CONSTANTS.NETWORK.FACEBOOK}
              type={BUTTON_CONSTANTS.TYPE.SIGN_IN}
              asLogo
            />
            <SocialButton
              ga={{ category: GACategory, action: 'Login Apple' }}
              network={BUTTON_CONSTANTS.NETWORK.APPLE}
              type={BUTTON_CONSTANTS.TYPE.SIGN_IN}
              asLogo
            />
          </div>
          <OrDivider />
        </div>
      )}
      <SignUpForm
        onAlreadyHasAccount={() => changeTab('SIGN_IN')}
        onRequiresVerification={() => changeTab('VERIFY_EMAIL_PROMPT')}
      />
    </div>
  );
};

export default SignUpOptions;
