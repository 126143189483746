import classnames from 'classnames';
import { ForwardedRef, InputHTMLAttributes, ReactElement, forwardRef } from 'react';

type Props = {
  /**
   * We have to override the existing unwanted styles with a high specificity selector.
   * This means additional selector properties outside the current specificity need to be applied as '!important'.
   * Tailwind CSS classes need to by applied with the '!' prefix.
   */
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const StyledInput = forwardRef<HTMLInputElement, Props>(
  ({ className, ...rest }: Props, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
    const cssClasses = classnames('styled-input', className);

    return <input className={cssClasses} {...rest} ref={ref} />;
  },
);

export default StyledInput;
