import React from 'react';
import ReactGA from 'react-ga4';
import { createRoutesFromElements, Route } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import { GeneralErrorPage } from '../pages/error-page';

/* global aliroConfig */

// @ts-ignore
const googleAnalyticsId = aliroConfig.GOOGLE_ANALYTICS_ID;
// @ts-ignore
const reactGADebug = aliroConfig.GOOGLE_ANALYTICS_DEBUG === 'true';

if (googleAnalyticsId) {
  if (reactGADebug) {
    console.log('Loading Google Analytics > ', googleAnalyticsId);
  }
  ReactGA.initialize(googleAnalyticsId, {
    testMode: reactGADebug,
  });
}

const EmployerRouter = React.lazy(() => import('./routers/EmployerRouter'));
const AdminRouter = React.lazy(() => import('./routers/AdminRouter'));
const AppRouter = React.lazy(() => import('./routers/AppRouter'));

export type CreateRouterOpts = {
  isPwaOrMobile?: boolean;
};

const FallBack = () => <div className="min-vh-100 min-vw-100" />;

export const createRouter = ({ isPwaOrMobile }: CreateRouterOpts = { isPwaOrMobile: false }) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route Component={App} errorElement={<GeneralErrorPage />}>
        {!isPwaOrMobile && (
          <Route
            path="employers/*"
            element={
              <React.Suspense fallback={<FallBack />}>
                <EmployerRouter />
              </React.Suspense>
            }
          />
        )}

        {!isPwaOrMobile && (
          <Route
            path="admin/*"
            element={
              <React.Suspense fallback={<FallBack />}>
                <AdminRouter />
              </React.Suspense>
            }
          />
        )}

        <Route
          path="*"
          element={
            <React.Suspense fallback={<FallBack />}>
              <AppRouter />
            </React.Suspense>
          }
        />
      </Route>,
    ),
  );
};
