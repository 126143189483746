import { PropsWithChildren, useCallback, useState } from 'react';
import { AliroConfig } from '../../../global';
import appContext from './context';

export const AppContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [minLoadCompleteState, setMinLoadCompleteState] = useState<boolean>(true);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const setLoading = useCallback(
    (value: boolean): void => {
      if (value) {
        setLoadingState(true);
        setMinLoadCompleteState(false);
        setTimeout((): void => {
          setMinLoadCompleteState(true);
        }, 1000);
      } else {
        setLoadingState(false);
      }
    },
    [setLoadingState, setMinLoadCompleteState],
  );

  const config = (window as any).aliroConfig as AliroConfig;
  const siteIsMilitary = config.branding?.signupPageType === 'milconn';

  return (
    <appContext.Provider
      value={{
        config,
        siteIsMilitary,
        loading: loadingState || !minLoadCompleteState,
        setLoading,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default AppContextProvider;
