// This is a react hook to get / set a cookie value
// Uses react-query so the value is cached and can be invalidated and all usage of a given cookie key will be updated

import { useQuery } from '@tanstack/react-query';
import Cookies from 'cookies-js';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export const useCookieValue = <Value extends string = string>(cookieKey: string, expireInDays?: number) => {
  const query = useQuery(
    ['cookie', cookieKey],
    (): Value | undefined => {
      return Cookies.get(cookieKey) as Value | undefined;
    },
    { initialData: Cookies.get(cookieKey) },
  );

  const set = (value: Value) => {
    if (expireInDays !== undefined) {
      const expiry = dayjs().add(expireInDays, 'days').toDate();
      Cookies.set(cookieKey, value, { expires: expiry });
    } else {
      Cookies.set(cookieKey, value);
    }

    query.refetch();
  };

  return { value: query.data, set };
};

let callbackTimeouts: NodeJS.Timeout[] = [];

// This is a helper hook to count events and trigger callbacks when a certain count is reached
// E.g. to count the number of clicks on a button and trigger a modal after 5 clicks using the callback
export const useCookieEventCounter = (
  cookieKey: string,
  enabled: boolean,
  onCountCallbacks?: Array<{ count: number; delayMs?: number; onHit: () => void }>,
  expireInDays?: number,
) => {
  const { value, set } = useCookieValue(cookieKey, expireInDays);
  const count = value ? parseInt(value) : 0;
  const increment = () => {
    if (!enabled) return;

    set((count + 1).toString());

    const callback = onCountCallbacks?.find((cb) => cb.count === count + 1);
    if (callback) {
      const timeout = setTimeout(callback.onHit, callback.delayMs || 0);
      callbackTimeouts.push(timeout);
    }
  };

  useEffect(() => {
    return () => {
      callbackTimeouts.forEach((timeout) => clearTimeout(timeout));
      callbackTimeouts = [];
    };
  }, []);

  return { count, increment };
};
