import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAppContext from '../../context/app-context';

const LoginModalTop = ({ isCoreSite }: { isCoreSite: boolean }) => {
  const { config } = useAppContext();
  const { t } = useTranslation();

  const logoSrc = config.branding.loginLogo;

  return (
    <div className="text-center mb-[32px]">
      <img alt="logo" className="logo" src={logoSrc} height="26" />
      <div>{t('keepTrackOfYourReferrals')}</div>
    </div>
  );
};

export default LoginModalTop;
