import Axios from 'axios';
import Cookies from 'cookies-js';
import SESSION_STORAGE_KEYS from '../constants/SessionStorageConstants';

const axios = Axios.create({
  withCredentials: true,
  validateStatus: (status) => status >= 200 && status < 300,
});

export const getRegisterChallengeOptions = () => {
  return axios.get('api/v2/webauthn/register').then((response) => {
    return response.data;
  });
};

export const postVerifyRegistration = (data: unknown) => {
  return axios.post('api/v2/webauthn/verify-registration', data).then((response) => {
    return response.data;
  });
};

export const getLoginChallengeOptions = (email: string) => {
  return axios.get(`api/v2/webauthn/login?email=${encodeURIComponent(email)}`).then((response) => {
    return response.data;
  });
};

export const postVerifyLogin = (email: string, data: unknown) => {
  return axios.post(`api/v2/webauthn/verify-login?email=${encodeURIComponent(email)}`, data).then((response) => {
    return response.data;
  });
};

export const getLocalstorageWebauthnRegisteredEmails = (): string | null => {
  return localStorage?.getItem('webauthnEmail') || null;
};

export const getLocalstorageWebauthnCredentialId = (): string | null => {
  return localStorage?.getItem('webauthnCredentialId') || null;
};

export const updateLocalstorageWithWebauthnRegistration = (registeredEmail: string, credentialId: string) => {
  localStorage?.setItem('webauthnEmail', registeredEmail);
  localStorage?.setItem('webauthnCredentialId', credentialId);
};

export const clearLocalstorageWebauthnRegisteredEmails = () => {
  localStorage?.removeItem('webauthnEmail');
  localStorage?.removeItem('webauthnCredentialId');
};

export const addHasTriedWebAuthnCookie = () => {
  const in1Min = new Date(new Date().getTime() + 60 * 1000);
  Cookies.set(SESSION_STORAGE_KEYS.USER.HAS_TRIED_WEB_AUTHN, 'true', {
    // @ts-ignore
    domain: `.${window.aliroConfig.platformDomain}`,
    secure: process.env.NODE_ENV === 'production',
    expires: in1Min,
  });
};

export const getHasTriedWebAuthnCookie = (): boolean => {
  return Cookies.get(SESSION_STORAGE_KEYS.USER.HAS_TRIED_WEB_AUTHN) === 'true';
};
