type TFunction = (key: string) => string;
export const buildYupLocale = (t: TFunction) => {
  return {
    mixed: {
      required: `\${path} ${t('isRequired')}`,
      notNull: `\${path} ${t('isRequired')}`,
      notType: `\${path} ${t('isRequired')}`,
      default: `\${path} ${t('isInvalid')}`,
    },
    string: {
      email: `\${path} ${t('mustBeValidEmail')}`,
      matches: `\${path} ${t('isInvalid')}`,
      length: `\${path} ${t('mustBeAtMost')} \${length} ${t('charsLong')}`,
      max: `\${path} ${t('mustBeAtMost')} \${max} ${t('charsLong')}`,
      min: `\${path} ${t('mustBeAtLeast')} \${min} ${t('charsLong')}`,
    },
    number: {
      max: `\${path} ${t('mustBeAtMost')} \${max} ${t('charsLong')}`,
      min: `\${path} ${t('mustBeAtLeast')} \${min} ${t('charsLong')}`,
      type: `\${path} ${t('isInvalid')}`,
    },
  };
};
