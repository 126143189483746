import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { MfaSessionDto } from '@alirosoftware/nest-mfa/client';
import { UseQueryOptions } from '@tanstack/react-query/src/types';

export const QUERY_KEY_MFA_SESSION = 'mfaSession';

export const useMfaSession = (token: string, opts: Partial<UseQueryOptions<unknown, unknown, MfaSessionDto>> = {}) =>
  useQuery<unknown, unknown, MfaSessionDto>({
    queryKey: [QUERY_KEY_MFA_SESSION, token],
    queryFn: () => axios.get<MfaSessionDto>(`/api/v2/auth/mfa/${token}`).then((r) => r.data),
    ...opts,
  });
