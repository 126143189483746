import { ApiResponse } from '../../../types/core';
import { ButtonProps } from '../core/button/button';
import { MediaBackendAdapter } from '../media-management/types';

export type Locale = 'de-DE' | 'en-US' | 'en-GB' | 'es-ES' | 'es-419' | 'fr-CA' | 'it-IT';

// Locale label mappings
export const LOCALE_LABELS: Record<Locale, string> = {
  'en-US': 'English (US)',
  'en-GB': 'English (UK)',
  'de-DE': 'Deutsch',
  'es-ES': 'Español',
  'es-419': 'Español (LatAm)',
  'fr-CA': 'Français (Canada)',
  'it-IT': 'Italiano',
};

export type TemplateContent = {
  locale: Locale;
};

export type TemplateType = 'sms' | 'email' | 'notification';

export type MailContentTemplate = TemplateContent & {
  body: string; // The EJS template, previously stored in File System
  subject: string; // Previously stored in the core MailContent object
};

export type NotificationTemplateContent = TemplateContent & {
  title: string;
  body: string;
  link?: string;
  linkText?: string;
  sendNotification: boolean;
  expiryDays: number;
};

export type SmsTemplateContent = TemplateContent & {
  body: string;
};

export type TemplateFollowup = {
  delayInDays: number;
  recurring?: boolean;
  recurringDays?: number;
} & (
  | {
      type: string;
      name?: never;
    }
  | {
      type?: never;
      name: string;
    }
);

export interface DynamicDataTemplate {
  key: string;
  type: 'value' | 'html';
  ejs: string;
  label: string;
}

export type UpdateEmailTemplateRequest = MailContentTemplate;

export type UpdateTemplateResponse<Content extends TemplateContent> = ApiResponse<{ templates: Content[] }>;

export type BuildEmailTemplatePreviewResponse = ApiResponse<{ preview: string }>;

export type GetTemplatesResponse<Content extends TemplateContent> = ApiResponse<{
  templates: Content[];
  followup: TemplateFollowup[];
}>;

export type GetTemplateDynamicDataResponse = ApiResponse<{ templates: DynamicDataTemplate[] }>;

export type UseBuildEmailTemplatePreviewResult = {
  error: string | undefined;
  loading: boolean;
  buildPreview: (template: string, type: 'body' | 'subject') => Promise<string | null>;
};

export type UseDeleteTemplateResult<Content extends TemplateContent> = {
  error: string | undefined;
  loading: boolean;
  deleteTemplate: (template: Content) => void;
};

export type UseTemplateDynamicDataResult = {
  error: string | undefined;
  loaded: boolean;
  loading: boolean;
  reload: () => Promise<void>;
  templates: DynamicDataTemplate[];
};

export type UseTemplatesResult<Content extends TemplateContent> = {
  error: string | undefined;
  loaded: boolean;
  loading: boolean;
  reload: () => Promise<void>;
  templates: Content[];
  followups: TemplateFollowup[];
};

export type UseUpdateTemplateResult<Content extends TemplateContent> = {
  error: string | undefined;
  loading: boolean;
  updateTemplate: (template: Content) => void;
};

export type EmailTemplatesBackendAdapter = {
  useBuildEmailTemplatePreview: (opts: { emailType: string }) => UseBuildEmailTemplatePreviewResult;
  useEmailTemplateDynamicData: (opts: { emailType: string }) => UseTemplateDynamicDataResult;
  useUpdateEmailTemplate: (opts: {
    emailType: string;
    onTemplateUpdated: (template: MailContentTemplate) => void;
  }) => UseUpdateTemplateResult<MailContentTemplate>;
  useEmailTemplates: (opts: { emailType: string }) => UseTemplatesResult<MailContentTemplate>;
  useDeleteEmailTemplate: (opts: {
    emailType: string;
    onTemplateDeleted?: (template: MailContentTemplate) => void;
  }) => UseDeleteTemplateResult<MailContentTemplate>;
};

export type GenericTemplateOpts = {
  templateName: string;
};

export type GenericTemplatesBackendAdapter<Content extends TemplateContent> = {
  useUpdateTemplate: (
    opts: GenericTemplateOpts & {
      onTemplateUpdated: (template: Content) => void;
    },
  ) => UseUpdateTemplateResult<Content>;
  useTemplateDynamicData: (opts: GenericTemplateOpts) => UseTemplateDynamicDataResult;
  useTemplates: (opts: GenericTemplateOpts) => UseTemplatesResult<Content>;
  useDeleteTemplate: (
    opts: GenericTemplateOpts & {
      onTemplateDeleted?: (template: Content) => void;
    },
  ) => UseDeleteTemplateResult<Content>;
  useBuildEmailTemplatePreview: (opts: { emailType: string }) => UseBuildEmailTemplatePreviewResult;
};

export type CreateTemplateFormProps<Content extends TemplateContent> = {
  templates: Content[];
  templateType: TemplateType;
  templateName: string;
  onTemplateUpdated: (template: Content) => void;
  backendAdapter: GenericTemplatesBackendAdapter<Content>;
};

export type EditTemplateButtonProps = Omit<ButtonProps, 'onClick'> & {
  apiBaseUrl: string;
  templateType: TemplateType;
  templateName: string;
  title: string;
  employerId: string;
};

export type EditTemplateFormProps<Content extends TemplateContent> = {
  initial: Content;
  contentCssUrl: string;
  templateType: TemplateType;
  templateName: string;
  title: string;
  onTemplateUpdated: (template: Content) => void;
  backendAdapter: GenericTemplatesBackendAdapter<Content>;
  mediaBackendAdapter: MediaBackendAdapter | undefined;
};
