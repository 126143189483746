import { useCallback } from 'react';
import axios from 'axios';
import { useLoader } from '../../../hooks/useLoader';
import { UpdateTemplateResponse, TemplateContent, UseUpdateTemplateResult, TemplateType } from '../types';
import { t } from 'i18next';

export type UseUpdateTemplateOpts<Content extends TemplateContent> = {
  apiBaseUrl: string;
  employerId: string;
  templateType: TemplateType;
  templateName: string;
  onTemplateUpdated?: (template: Content) => void;
};

export const useUpdateTemplate = <Content extends TemplateContent>({
  apiBaseUrl,
  employerId,
  templateType,
  templateName,
  onTemplateUpdated,
}: UseUpdateTemplateOpts<Content>): UseUpdateTemplateResult<Content> => {
  const { loading, error, run, setError } = useLoader({ cancelOnUnmount: false });

  const updateTemplate = useCallback(
    (template: Content) => {
      run(
        axios
          .patch<UpdateTemplateResponse<Content>>(
            `/api/v2/employer/${employerId}/templates/${templateType}/${templateName}`,
            template,
            {
              baseURL: apiBaseUrl,
            },
          )
          ?.then((result) => {
            setError(undefined);
            if (!result) {
              // Cancelled
              return;
            }

            if (result?.data?.success && onTemplateUpdated) {
              onTemplateUpdated(template);
            } else if (!result.data?.success) {
              setError(result.data?.error || 'Unknown Error');
            }
          })
          ?.catch((e) => {
            if (e.response?.data.translateKey) {
              setError(t(e.response.data.translateKey) + e.response.data.errorData?.toString() || '');
            } else {
              setError(e.response?.data?.message || e?.message || e?.toString() || 'Unknown Error');
            }
          }),
      );
    },
    [apiBaseUrl, employerId, templateType, templateName, run, onTemplateUpdated, setError],
  );

  return {
    error,
    loading,
    updateTemplate,
  };
};
