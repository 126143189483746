import { CustomDetector } from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/es-mx';
import 'moment/locale/fr';
import 'moment/locale/it';
import { getLanguage } from '@alirosoftware/aliro-common';

function localeToMomentLocale(locale: string) {
  if (!locale) locale = 'en-US';
  switch (locale) {
    case 'en-GB':
      return 'en-gb';
    case 'es-419':
      return 'es-mx';
    default:
      return locale.substring(0, 2);
  }
}

function getFlag(language: string) {
  switch (language) {
    case 'de-DE':
      return 'de';
    case 'en-GB':
      return 'gb';
    case 'en-US':
      return 'us';
    case 'es-ES':
      return 'es';
    case 'es-419':
      return 'mx';
    case 'fr-CA':
      return 'fr';
    case 'it-IT':
      return 'it';
    default:
      return 'us';
  }
}

export default (): CustomDetector => ({
  cacheUserLanguage(lng) {
    window.localStorage.setItem('i18nextLng', lng);
    import('superagent-defaults')
      .then((superagent: any) => {
        if (typeof superagent === 'function') {
          superagent().set('accept-language', lng);
        }
      })
      .catch();
    moment.locale(localeToMomentLocale(lng));
    const flag = getFlag(lng);
    window.localStorage.setItem('LngFlag', flag);
  },
  lookup() {
    const language = window.localStorage.getItem('i18nextLng');
    if (language) {
      const langLookup = getLanguage(language);
      return langLookup;
    }
    const navLangLookup = getLanguage(window.navigator.language);
    return navLangLookup;
  },
  name: 'detector',
});
