const defaults = require('superagent-defaults');

const superagent = defaults();

function setLanguageHeaders(language) {
  superagent.set('accept-language', language);
}

if (window.localStorage) {
  setLanguageHeaders(window.localStorage?.getItem('i18nextLng'));
} else {
  setLanguageHeaders('en-US');
}

export default superagent;
export { setLanguageHeaders };
