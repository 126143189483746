import { createContext, PropsWithChildren, useContext, useState } from 'react';

export type ReferralContextState = {
  referralModalOpen: boolean;
  referralJobId: string | null;
  referralLink: string | null;
};

export type ReferralContextValue = ReferralContextState & {
  setReferralModalOpen: (open: boolean, jobId?: string, referralLink?: string) => void;
};

export const ReferralContext = createContext<ReferralContextValue>({
  referralModalOpen: false,
  referralJobId: null,
  referralLink: null,
  setReferralModalOpen: () => {},
});

export const ReferralProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<ReferralContextState>({
    referralModalOpen: false,
    referralJobId: null,
    referralLink: null,
  });

  const setReferralModalOpen = (open: boolean, jobId?: string, referralLink?: string) => {
    setState({ referralModalOpen: open, referralJobId: jobId || null, referralLink: referralLink || null });
  };

  return (
    <ReferralContext.Provider
      value={{
        ...state,
        setReferralModalOpen,
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
};

export const useReferralContext = () => useContext(ReferralContext);
