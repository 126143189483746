import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Only return a deviceId if not SSR'd

export const useDeviceId = () => {
  const isServer = typeof window === 'undefined';
  const [deviceId, setDeviceId] = useState<string | undefined>(
    isServer ? undefined : window?.localStorage?.getItem('deviceId') || undefined,
  );

  useEffect(() => {
    if (isServer) {
      return;
    }

    if (!deviceId) {
      const newDeviceId = uuidv4();
      window?.localStorage.setItem('deviceId', newDeviceId);
      setDeviceId(newDeviceId);
    }
  }, [deviceId, isServer]);

  return deviceId;
};
