import { useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import Cookies from 'cookies-js';
import { Button, ButtonColor, ButtonVariant, Icon, Modal } from '@alirosoftware/web-components';
import BUTTON_CONSTANTS from '../../constants/ButtonConstants';
import useAppContext from '../../context/app-context';
import SocialButton from '../SocialButton/SocialButtonComponent';
import SESSION_STORAGE_KEYS from '../../constants/SessionStorageConstants';
import { useTranslation } from 'react-i18next';
import { useLoginSignupContext } from '../../context/LoginSignupModalContext';
import { useAfterLogin } from '../../hooks/useAfterLogin';
import ResetPasswordForm from './ResetPasswordForm';
import ReCAPTCHA from 'react-google-recaptcha';
import axios, { AxiosError, AxiosResponse } from 'axios';
import LoginModalTop from './LoginModalTop';
import SignUpOptions from './SignUpOptions';
import SignInOptions from './SignInOptions';
import VerifyEmailPrompt from './VerifyEmailPrompt';
import { useMutation } from '@tanstack/react-query';
import { MfaLogin } from './mfa/MfaLogin';
import classnames from 'classnames';
import { FaChevronLeft } from 'react-icons/fa';

const MODAL_VIEWS = {
  INITIAL: 'INITIAL',
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_CONFIRMATION: 'FORGOT_PASSWORD_CONFIRMATION',
  MFA: 'MFA',
  VERIFY_EMAIL_PROMPT: 'VERIFY_EMAIL_PROMPT',
} as const;

type ModalViews = (typeof MODAL_VIEWS)[keyof typeof MODAL_VIEWS];
const unToppedViews: ModalViews[] = ['MFA', 'VERIFY_EMAIL_PROMPT'];
const backableViews: ModalViews[] = ['SIGN_IN', 'SIGN_UP', 'FORGOT_PASSWORD'];

const LogInSignUpModalComponent = () => {
  const { config } = useAppContext();
  const recaptchaRef = useRef();
  const { afterLogin } = useAfterLogin();
  const { closeLoginSignupModal, ...context } = useLoginSignupContext();
  const { t } = useTranslation();
  const isOnCore = config.isOnCore();
  const useSso = !!config.employerPrefs?.useSso;
  const GACategory = 'Login Signup Modal';

  const [state, setStateRaw] = useState({
    email: '',
    errorMessages: null,
    modalView: MODAL_VIEWS.INITIAL as ModalViews,
    resetEmail: '',
    sendingForgotPasswordEmail: false,
    loginFailure: 0,
    erpSignUpUrl: false,
    emailValid: false,
    passwordValid: false,
    resetEmailValid: false,
    loading: false,
    mfaToken: null,
  });

  const setState = (newState) => {
    setStateRaw((prevState) => ({ ...prevState, ...newState }));
  };

  const init = () => {
    const erpSignUpUrl = Cookies.get(SESSION_STORAGE_KEYS.USER.ERP_LOGIN_URL);
    if (erpSignUpUrl && erpSignUpUrl !== 'undefined') {
      setState({ erpSignUpUrl });
      Cookies.expire(SESSION_STORAGE_KEYS.USER.ERP_LOGIN_URL, { domain: `.${config.platformDomain}` });
    }

    if (`${Cookies.get(SESSION_STORAGE_KEYS.USER.LOGIN_ERROR)}` !== 'undefined') {
      setState({ errorMessages: Cookies.get(SESSION_STORAGE_KEYS.USER.LOGIN_ERROR) });
      Cookies.expire(SESSION_STORAGE_KEYS.USER.LOGIN_ERROR, { domain: `.${config.platformDomain}` });
      open();
    }
    setState({
      loginLogo: config.branding.loginLogo,
    });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setState({ email: context.email });
  }, [context.email]);

  const onClose = () => {
    const newState = Object.assign(state, {
      email: '',
      resetEmail: '',
      errorMessages: null,
      erpSignUpUrl: false,
      modalView: MODAL_VIEWS.INITIAL,
    });
    setState(newState);

    closeLoginSignupModal?.();
  };

  const close = () => {
    ReactGA.event({
      category: GACategory,
      action: 'Close Log In / Sign Up Modal',
    });
    closeLoginSignupModal?.();
  };

  const switchToSignIn = (e) => {
    e.preventDefault();
    setState({ resetEmail: '', errorMessages: false, modalView: MODAL_VIEWS.SIGN_IN });
    ReactGA.event({
      category: GACategory,
      action: 'Back to Sign in link',
    });
  };

  type AttempLoginParams = Record<'email' | 'password', string>;
  const attemptLoginMutation = useMutation<AxiosResponse, AxiosError<any>, AttempLoginParams>({
    mutationKey: ['email-login'],
    mutationFn: async (loginParams) => {
      const recaptchaToken = (await (recaptchaRef.current as any)?.executeAsync()) as string;

      ReactGA.event({ category: GACategory, action: 'Login Complete' });

      return await axios.post(`/auth/user/login?token=${encodeURIComponent(recaptchaToken)}`, loginParams, {
        validateStatus: (status) => status === 200,
      });
    },
    onSuccess: (response) => {
      if (response.data.reload) {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.USER.DATA);
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.EMPLOYER.DATA);
        window.location.reload();
      } else {
        afterLogin({ detail: response.data });
        close();
      }
      Cookies.set(SESSION_STORAGE_KEYS.USER.EXTERNAL_LOGIN_TYPE, 'NONE', {
        domain: `.${config.platformDomain}`,
        secure: process.env.NODE_ENV === 'production',
      });
    },
    onError: (error) => {
      const response = error.response;

      if (response.status === 403 && response.data?.host) {
        window.location = `${response.data.host}/email-verification/email/${response.data.email}` as any;
      } else if (response.status === 428 && response.data.token) {
        return setState({ modalView: MODAL_VIEWS.MFA, mfaToken: response.data.token });
      } else {
        if (state.loginFailure === 3) {
          return setState({ modalView: MODAL_VIEWS.FORGOT_PASSWORD });
        }
        setState({
          loginFailure: state.loginFailure + 1,
          erpSignUpUrl: (response && response.data && response.data['erpSignUpUrl']) || false,
          errorMessages: (response && response.data && response.data['message']) || t('unknownError'),
        });
      }
    },
  });

  const submitLoginForm = async (logInParams) => {
    attemptLoginMutation.mutate(logInParams);
  };

  const { errorMessages, modalView } = state;

  let serverErrorMessages;
  if (errorMessages) {
    serverErrorMessages = <div className="error-message">{errorMessages}</div>;
  }

  const onMfaSuccess = (data) => {
    afterLogin({ detail: data });
    close();
  };

  const onMfaFail = (message) => {
    setState({ errorMessages: message, modalView: MODAL_VIEWS.SIGN_IN });
  };

  useEffect(() => {
    attemptLoginMutation.reset();
  }, [modalView]);

  const showBackButton = backableViews.includes(modalView);
  const handleBackButton = () => {
    if (modalView === MODAL_VIEWS.SIGN_IN || modalView === MODAL_VIEWS.SIGN_UP) {
      setState({ modalView: MODAL_VIEWS.INITIAL });
    } else if (modalView === MODAL_VIEWS.FORGOT_PASSWORD) {
      setState({ modalView: MODAL_VIEWS.SIGN_IN });
    }
  };

  const getToken = async () => {
    const recaptchaToken = (await (recaptchaRef.current as any)?.executeAsync()) as string;
    return recaptchaToken;
  };

  const handleMfaLoginError = (token: string) => {
    setState({ modalView: MODAL_VIEWS.MFA, mfaToken: token });
  };

  return (
    <>
      <Modal
        className="flex flex-col"
        onClose={onClose}
        open={context.isModalOpen}
        size="default"
        // Stop propagation of click events to prevent modal from closing when clicking inside
        onInteractOutside={(e: MouseEvent) => {
          e?.stopPropagation();
          e?.stopImmediatePropagation();
          e?.preventDefault();
        }}
      >
        {showBackButton && (
          <div className="text-left mb-[8px]">
            <Button variant={ButtonVariant.Text} className="" onClick={handleBackButton}>
              <Icon icon={FaChevronLeft} className="text-blue-800" /> <span className="text-black">{t('back')}</span>
            </Button>
          </div>
        )}
        <div
          className={classnames('mx-auto relative', {
            'max-w-[300px]': modalView !== MODAL_VIEWS.MFA,
          })}
          data-cy="login-signup-modal"
        >
          {!unToppedViews.includes(modalView) && <LoginModalTop isCoreSite={isOnCore} />}
          {modalView === MODAL_VIEWS.INITIAL && (
            <>
              <Button
                className="!w-full"
                color={ButtonColor.Primary}
                data-cy="signup-button"
                onClick={() => setState({ modalView: MODAL_VIEWS.SIGN_UP })}
                type="button"
              >
                {t('createMyAccount')}
              </Button>
              <Button
                className="!w-full !text-16 mt-[16px] !leading-none"
                data-cy="login-button"
                onClick={() => setState({ modalView: MODAL_VIEWS.SIGN_IN })}
                type="button"
                variant={ButtonVariant.Ghost}
              >
                {t('iAlreadyHaveAccount')}
              </Button>
              {useSso && (
                <div className="mt-[16px] text-center">
                  <SocialButton
                    ga={{ category: GACategory, action: 'Login SSO' }}
                    network={BUTTON_CONSTANTS.NETWORK.SSOEMPLOYEES}
                    type={BUTTON_CONSTANTS.TYPE.SIGN_IN}
                    data-testid="sso-login"
                    asText={true}
                  />
                  <div className="text-gray-500 -mt-2">{t('forEmployeesWithAWorkEmail')}</div>
                </div>
              )}
            </>
          )}
          {modalView === MODAL_VIEWS.SIGN_UP && (
            <SignUpOptions
              isCoreSite={isOnCore}
              changeTab={(tab: string) => setState({ modalView: tab })}
              close={closeLoginSignupModal}
            />
          )}
          {modalView === MODAL_VIEWS.SIGN_IN && (
            <SignInOptions
              getToken={getToken}
              onLogin={submitLoginForm}
              changeTab={(tab: string) => setState({ modalView: tab })}
              isCoreSite={isOnCore}
              onMfaError={handleMfaLoginError}
            />
          )}
          {modalView === MODAL_VIEWS.FORGOT_PASSWORD && (
            <ResetPasswordForm onSuccess={() => setState({ modalView: MODAL_VIEWS.FORGOT_PASSWORD_CONFIRMATION })} />
          )}
          {modalView === MODAL_VIEWS.FORGOT_PASSWORD_CONFIRMATION && (
            <div className="forgot-password-confimation-modal">
              <h2>{t('passwordResetSent')}</h2>
              <p>{t('emailedResetLink')}</p>
              <img src="https://images.onaliro.com/client/icon_success.svg" className="success-icon" />
              <a href="#" onClick={switchToSignIn} className="sign-in-link">
                {t('backToLogin')}
              </a>
            </div>
          )}
          {modalView === MODAL_VIEWS.MFA && (
            <MfaLogin token={state.mfaToken} onSuccess={onMfaSuccess} onFail={onMfaFail} />
          )}
          {modalView === MODAL_VIEWS.VERIFY_EMAIL_PROMPT && <VerifyEmailPrompt />}
          {attemptLoginMutation.isLoading && (
            <div className="pointer-events-none absolute inset-0 w-full h-full bg-white/40" />
          )}
        </div>
      </Modal>
      {context.isModalOpen ? (
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={config.GOOGLE_RECAPTCHA_SITE_KEY} />
      ) : null}
    </>
  );
};

export default LogInSignUpModalComponent;
