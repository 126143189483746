const OrDivider = () => {
  return (
    <div className="flex items-center justify-center my-[32px]">
      <div className="w-full border-t border-gray-300/20 border-solid" />
      <div className="px-3 text-gray-500 mx-4">or</div>
      <div className="w-full border-t border-gray-300/20 border-solid" />
    </div>
  );
};

export default OrDivider;
