import { FC } from 'react';

type Props = {
  children: React.ReactNode;
};

const AuthErrorMessage: FC<Props> = ({ children }) => {
  return (
    <div className="px-4 py-2 rounded bg-error/10 border-0 border-l-4 border-l-error border-solid">{children}</div>
  );
};

export default AuthErrorMessage;
