import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import firebaseApp from '../utils/firebase';
import { useDeviceId } from './device';
import axios from 'axios';

export type UseRegisterForPushNotifications = {
  userId?: string | null;
  enabled: boolean;
  autoRegister: boolean;
  onPermissionDenied?: () => void;
  onError?: (error: string) => void;
  onRegistered?: (token: string) => void;
};

export type UseRegisterForPushNotificationsResult = {
  canRegister: boolean;
  isRegistered: boolean;
  tryRegister: () => Promise<void>;
};

export const useRegisterForPushNotifications = ({
  userId,
  enabled,
  autoRegister,
  onPermissionDenied,
  onError,
  onRegistered,
}: UseRegisterForPushNotifications): UseRegisterForPushNotificationsResult => {
  const deviceId = useDeviceId();
  const [token, setToken] = useState('');
  const [serviceWorkerRegistration, setServiceWorkerRegistration] = useState<ServiceWorkerRegistration | null>(null);

  const notificationsAvailable = 'Notification' in window;
  const fcmAvailable =
    notificationsAvailable &&
    typeof window !== 'undefined' &&
    !!('serviceWorker' in navigator && typeof window !== 'undefined');

  // Only allow registration if the user is logged in, FCM is available and notifications are enabled on the host
  const canRegister = React.useMemo(
    () => !!userId && fcmAvailable && !!aliroConfig?.employerPrefs?.useNotifications && enabled,
    [userId, enabled, fcmAvailable],
  );

  const registerTokenWithApi = useCallback(
    async (token: string) => {
      await axios.post('/api/v2/notifications/token', { deviceId, token });
    },
    [deviceId],
  );

  const retrieveToken = useCallback(async (): Promise<string | null> => {
    if (!canRegister) {
      console.error('Cannot register for push notifications');
      onError?.('Cannot register for push notifications');
      return null;
    }

    if (!serviceWorkerRegistration) {
      console.error('Service worker registration not available');
      onError?.('Error registering service worker');
      return null;
    }

    try {
      // Retrieve the notification permission status
      const permission = await window.Notification?.requestPermission();
      if (permission !== 'granted') {
        console.log('Notification permission denied');
        if (onPermissionDenied) {
          onPermissionDenied();
        }

        return null;
      }

      // Check if permission is granted before retrieving the token
      console.log('Notification permission granted');
      const messaging = getMessaging(firebaseApp);
      const newToken = await getToken(messaging, {
        vapidKey: 'BIMvb9MBnZ-pKHjc0UkU8eO86N7YsMA-VBYrjOZyLN5SHtw4SuVeCbfJuccr9lrfkqRlnL18ahFncwiuWkSK_Qo',
        serviceWorkerRegistration,
      });

      return newToken;
    } catch (error) {
      console.error('An error occurred while retrieving token:', error);
      onError?.(error);
      return null;
    }
  }, [canRegister, serviceWorkerRegistration]);

  // Check if the token is already available in local storage - if not register it with the API
  const tryRegister = useCallback(async () => {
    console.log('Trying to register for push notifications');
    if (!canRegister) {
      console.error('Cannot register for push notifications');
      return;
    }

    const token = await retrieveToken();
    if (!token) {
      return;
    }

    const existingTokenJson = localStorage?.getItem('fcmToken');
    if (existingTokenJson) {
      const { token: existingToken, userId: existingUserId } = JSON.parse(existingTokenJson);
      if (existingToken === token && existingUserId === userId) {
        console.log('Token already registered with API');
        setToken(token);
        onRegistered?.(token);
        return;
      }
    }

    console.log('Registration token available. Registering with API');

    registerTokenWithApi(token).then(() => {
      localStorage?.setItem('fcmToken', JSON.stringify({ token, userId }));
      setToken(token);
      onRegistered?.(token);
    });
  }, [canRegister, userId, registerTokenWithApi, retrieveToken]);

  useEffect(() => {
    // Only register the service worker if the user is logged in and FCM is available
    if (!canRegister) {
      return;
    }

    // If the service worker is not registered, register it
    if (!serviceWorkerRegistration) {
      navigator.serviceWorker.register('/assets/js/firebase-messaging-sw.js').then((registration) => {
        console.log('Service worker registered');
        setServiceWorkerRegistration(registration);
      });
      return;
    }
  }, [canRegister, userId, fcmAvailable, retrieveToken, serviceWorkerRegistration]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const existingTokenJson = localStorage?.getItem('fcmToken');
    if (existingTokenJson) {
      const { token: existingToken, userId: existingUserId } = JSON.parse(existingTokenJson);
      if (existingUserId === userId) {
        setToken(existingToken);
      }
    }
  }, [userId]);

  useEffect(() => {
    if (autoRegister && !token && canRegister && serviceWorkerRegistration) {
      tryRegister();
    }
  }, [token, autoRegister, tryRegister, serviceWorkerRegistration]);

  return { canRegister, isRegistered: !!token, tryRegister };
};

export type UseListenForFirebaseMessages = {
  onMessageReceived: (payload: {
    data?:
      | Record<string, string>
      | {
          notificationId: string;
          title: string;
          body: string;
          link: string;
          read: 'true' | 'false';
        };
  }) => void;
};

export const useListenForFirebaseMessages = ({ onMessageReceived }: UseListenForFirebaseMessages) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return void 0;
    }

    const messaging = getMessaging(firebaseApp);
    const unsub = onMessage(messaging, onMessageReceived);

    return () => {
      unsub();
    };
  }, [onMessageReceived]);
};
