import { t } from 'i18next';
import React, { Fragment } from 'react';

const jobFunctions = (
  <Fragment>
    <option>Accounting/Auditing</option>
    <option>Administrative</option>
    <option>Advertising</option>
    <option>Analyst</option>
    <option>Art/Creative</option>
    <option>Background Screening</option>
    <option>Business Development</option>
    <option>Clinical</option>
    <option>Consulting</option>
    <option>Customer Service</option>
    <option>Distribution</option>
    <option>Design</option>
    <option>Education</option>
    <option>Engineering</option>
    <option>Environmental Safety</option>
    <option>Finance</option>
    <option>First Responders</option>
    <option>General Business</option>
    <option>General Labor</option>
    <option>Health Care Provider</option>
    <option>Human Resources</option>
    <option>Information Technology</option>
    <option>Law Enforcement/Military</option>
    <option>Legal</option>
    <option>Maintenance/Repair</option>
    <option>Management</option>
    <option>Manufacturing</option>
    <option>Marketing</option>
    <option>Other</option>
    <option>Public Relations</option>
    <option>Purchasing</option>
    <option>Product Management</option>
    <option>Project Management</option>
    <option>Production</option>
    <option>Quality Assurance</option>
    <option>Regulatory Affairs</option>
    <option>Research and Development</option>
    <option>Safety</option>
    <option>Sales</option>
    <option>Science</option>
    <option>Security</option>
    <option>Strategy/Planning</option>
    <option>Supply Chain</option>
    <option>Telecommunications</option>
    <option>Training</option>
    <option>Underwriting</option>
    <option>Utility Services</option>
    <option>Writing/Editing</option>
  </Fragment>
);

const industries = (
  <Fragment>
    <option>Accounting</option>
    <option>Airlines/Aviation</option>
    <option>Alternative Dispute Resolution</option>
    <option>Alternative Medicine</option>
    <option>Animation</option>
    <option>Apparel/Fashion</option>
    <option>Architecture/Planning</option>
    <option>Arts and Crafts</option>
    <option>Automotive</option>
    <option>Aviation/Aerospace</option>
    <option>Banking</option>
    <option>Biotechnology</option>
    <option>Broadcast Media</option>
    <option>Building Materials</option>
    <option>Business Supplies and Equipment</option>
    <option>Capital Markets</option>
    <option>Chemicals</option>
    <option>Civic/Social Organization</option>
    <option>Civil Engineering</option>
    <option>Commercial Real Estate</option>
    <option>Communications</option>
    <option>Computer/Network Security</option>
    <option>Computer Games</option>
    <option>Computer Hardware</option>
    <option>Computer Networking</option>
    <option>Computer Software</option>
    <option>Construction</option>
    <option>Consumer Electronics</option>
    <option>Consumer Goods</option>
    <option>Consumer Services</option>
    <option>Cosmetics</option>
    <option>Dairy</option>
    <option>Defense/Space</option>
    <option>Design</option>
    <option>Education Management</option>
    <option>E-Learning</option>
    <option>Electrical/Electronic Manufacturing</option>
    <option>Energy</option>
    <option>Entertainment</option>
    <option>Environmental Services</option>
    <option>Events Services</option>
    <option>Executive Office</option>
    <option>Facilities Services</option>
    <option>Farming</option>
    <option>Financial Services</option>
    <option>Fine Art</option>
    <option>Fishery</option>
    <option>Food/Beverages</option>
    <option>Food Production</option>
    <option>Fund-Raising</option>
    <option>Furniture</option>
    <option>Gambling/Casinos</option>
    <option>Glass, Ceramics/Concrete</option>
    <option>Global Infrastructure</option>
    <option>Government Administration</option>
    <option>Government Relations</option>
    <option>Graphic Design</option>
    <option>Healthcare</option>
    <option>Health, Wellness and Fitness</option>
    <option>Higher Education</option>
    <option>Hospital/Health Care</option>
    <option>Hospitality</option>
    <option>Human Resources</option>
    <option>Import and Export</option>
    <option>Individual/Family Services</option>
    <option>Industrial Automation</option>
    <option>Information Services</option>
    <option>Information Technology</option>
    <option>Information Technology and Services</option>
    <option>Insurance</option>
    <option>International Affairs</option>
    <option>International Trade and Development</option>
    <option>Internet</option>
    <option>Investment Banking</option>
    <option>Investment Management</option>
    <option>Judiciary</option>
    <option>Law Enforcement</option>
    <option>Law Practice</option>
    <option>Legal Services</option>
    <option>Legislative Office</option>
    <option>Leisure, Travel/Tourism</option>
    <option>Libraries</option>
    <option>Logistics and Supply Chain</option>
    <option>Luxury Goods/Jewelry</option>
    <option>Machinery</option>
    <option>Management Consulting</option>
    <option>Manufacturing</option>
    <option>Maritime</option>
    <option>Marketing and Advertising</option>
    <option>Market Research</option>
    <option>Mechanical or Industrial Engineering</option>
    <option>Media Production</option>
    <option>Medical Devices</option>
    <option>Medical Practice</option>
    <option>Mental Health Care</option>
    <option>Military</option>
    <option>Mining/Metals</option>
    <option>Motion Pictures and Film</option>
    <option>Museums and Institutions</option>
    <option>Music</option>
    <option>Nanotechnology</option>
    <option>Newspapers</option>
    <option>Nonprofit Organization Management</option>
    <option>Oil/Energy</option>
    <option>Online Media</option>
    <option>Outsourcing/Offshoring</option>
    <option>Package/Freight Delivery</option>
    <option>Packaging and Containers</option>
    <option>Paper/Forest Products</option>
    <option>Performing Arts</option>
    <option>Pharmaceuticals</option>
    <option>Philanthropy</option>
    <option>Photography</option>
    <option>Plastics</option>
    <option>Political Organization</option>
    <option>Primary/Secondary Education</option>
    <option>Printing</option>
    <option>Professional Training/Coaching</option>
    <option>Program Development</option>
    <option>Public Policy</option>
    <option>Public Relations and Communications</option>
    <option>Public Safety</option>
    <option>Public Service</option>
    <option>Publishing</option>
    <option>Railroad Manufacture</option>
    <option>Ranching</option>
    <option>Real Estate</option>
    <option>Recreational Facilities and Services</option>
    <option>Religious Institutions</option>
    <option>Renewables/Environment</option>
    <option>Research</option>
    <option>Restaurants</option>
    <option>Retail</option>
    <option>Screening Services</option>
    <option>Security and Investigations</option>
    <option>Semiconductors</option>
    <option>Shipbuilding</option>
    <option>Sporting Goods</option>
    <option>Sports</option>
    <option>Staffing and Recruiting</option>
    <option>Supermarkets</option>
    <option>Telecommunications</option>
    <option>Textiles</option>
    <option>Think Tanks</option>
    <option>Tobacco</option>
    <option>Translation and Localization</option>
    <option>Transportation</option>
    <option>Transportation/Trucking/Railroad</option>
    <option>Utilities</option>
    <option>Venture Capital/Private Equity</option>
    <option>Veterinary</option>
    <option>Warehousing</option>
    <option>Wholesale</option>
    <option>Wine and Spirits</option>
    <option>Wireless</option>
    <option>Writing and Editing</option>
  </Fragment>
);

const Options = {
  multiHire: () => (
    <Fragment>
      <option value="false">{t('multiHire.no')}</option>
      <option value="true">{t('multiHire.yes')}</option>
    </Fragment>
  ),
  promoted: () => (
    <Fragment>
      <option value="0">{t('none')}</option>
      <option value="50">{t('low')}</option>
      <option value="100">{t('high')}</option>
    </Fragment>
  ),
  remote: () => (
    <Fragment>
      <option value="false">{t('remote.no')}</option>
      <option value="true">{t('remote.yes')}</option>
    </Fragment>
  ),
  profileState: () => (
    <Fragment>
      <option value="">{t('selectAnOption')}</option>
      <optgroup label="US States">
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District Of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PR">Puerto Rico</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </optgroup>
      <optgroup label="Canadian Provinces">
        <option value="AB">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="MB">Manitoba</option>
        <option value="NB">New Brunswick</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NS">Nova Scotia</option>
        <option value="ON">Ontario</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="SK">Saskatchewan</option>
        <option value="NT">Northwest Territories</option>
        <option value="NU">Nunavut</option>
        <option value="YT">Yukon</option>
      </optgroup>
      <optgroup label="Other">
        <option value="Other">Other</option>
      </optgroup>
    </Fragment>
  ),
  state: (
    <Fragment>
      <option value="">Select State/Province</option>
      <optgroup label="US States">
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District Of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PR">Puerto Rico</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </optgroup>
      <optgroup label="Canadian Provinces">
        <option value="AB">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="MB">Manitoba</option>
        <option value="NB">New Brunswick</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NS">Nova Scotia</option>
        <option value="ON">Ontario</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="SK">Saskatchewan</option>
        <option value="NT">Northwest Territories</option>
        <option value="NU">Nunavut</option>
        <option value="YT">Yukon</option>
      </optgroup>
      <optgroup label="Other">
        <option value="Other">Other</option>
      </optgroup>
    </Fragment>
  ),
  countryAllowed: (
    <Fragment>
      <option value="" disabled hidden>
        Select Country
      </option>
      <option value="US">United States</option>
      <option value="AU">Australia</option>
      <option value="BE">Belgium</option>
      <option value="CA">Canada</option>
      <option value="FR">France</option>
      <option value="DE">Germany</option>
      <option value="GR">Greece</option>
      <option value="IE">Ireland</option>
      <option value="IT">Italy</option>
      <option value="NL">Netherlands</option>
      <option value="NZ">New Zealand</option>
      <option value="PL">Poland</option>
      <option value="ES">Spain</option>
      <option value="SE">Sweden</option>
      <option value="GB">United Kingdom</option>
    </Fragment>
  ),
  country: () => (
    <Fragment>
      <option value="">{t('selectAnOption')}</option>
      <option value="US">United States</option>
      <option value="CA">Canada</option>
      <option value="AF">Afghanistan</option>
      <option value="AX">Åland Islands</option>
      <option value="AL">Albania</option>
      <option value="DZ">Algeria</option>
      <option value="AS">American Samoa</option>
      <option value="AD">Andorra</option>
      <option value="AO">Angola</option>
      <option value="AI">Anguilla</option>
      <option value="AQ">Antarctica</option>
      <option value="AG">Antigua and Barbuda</option>
      <option value="AR">Argentina</option>
      <option value="AM">Armenia</option>
      <option value="AW">Aruba</option>
      <option value="AU">Australia</option>
      <option value="AT">Austria</option>
      <option value="AZ">Azerbaijan</option>
      <option value="BS">Bahamas</option>
      <option value="BH">Bahrain</option>
      <option value="BD">Bangladesh</option>
      <option value="BB">Barbados</option>
      <option value="BY">Belarus</option>
      <option value="BE">Belgium</option>
      <option value="BZ">Belize</option>
      <option value="BJ">Benin</option>
      <option value="BM">Bermuda</option>
      <option value="BT">Bhutan</option>
      <option value="BO">Bolivia, Plurinational State of</option>
      <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
      <option value="BA">Bosnia and Herzegovina</option>
      <option value="BW">Botswana</option>
      <option value="BV">Bouvet Island</option>
      <option value="BR">Brazil</option>
      <option value="IO">British Indian Ocean Territory</option>
      <option value="BN">Brunei Darussalam</option>
      <option value="BG">Bulgaria</option>
      <option value="BF">Burkina Faso</option>
      <option value="BI">Burundi</option>
      <option value="KH">Cambodia</option>
      <option value="CM">Cameroon</option>
      <option value="CA">Canada</option>
      <option value="CV">Cape Verde</option>
      <option value="KY">Cayman Islands</option>
      <option value="CF">Central African Republic</option>
      <option value="TD">Chad</option>
      <option value="CL">Chile</option>
      <option value="CN">China</option>
      <option value="CX">Christmas Island</option>
      <option value="CC">Cocos (Keeling) Islands</option>
      <option value="CO">Colombia</option>
      <option value="KM">Comoros</option>
      <option value="CG">Congo</option>
      <option value="CD">Congo, the Democratic Republic of the</option>
      <option value="CK">Cook Islands</option>
      <option value="CR">Costa Rica</option>
      <option value="CI">Côte d Ivoire</option>
      <option value="HR">Croatia</option>
      <option value="CU">Cuba</option>
      <option value="CW">Curaçao</option>
      <option value="CY">Cyprus</option>
      <option value="CZ">Czech Republic</option>
      <option value="DK">Denmark</option>
      <option value="DJ">Djibouti</option>
      <option value="DM">Dominica</option>
      <option value="DO">Dominican Republic</option>
      <option value="EC">Ecuador</option>
      <option value="EG">Egypt</option>
      <option value="SV">El Salvador</option>
      <option value="GQ">Equatorial Guinea</option>
      <option value="ER">Eritrea</option>
      <option value="EE">Estonia</option>
      <option value="ET">Ethiopia</option>
      <option value="FK">Falkland Islands (Malvinas)</option>
      <option value="FO">Faroe Islands</option>
      <option value="FJ">Fiji</option>
      <option value="FI">Finland</option>
      <option value="FR">France</option>
      <option value="GF">French Guiana</option>
      <option value="PF">French Polynesia</option>
      <option value="TF">French Southern Territories</option>
      <option value="GA">Gabon</option>
      <option value="GM">Gambia</option>
      <option value="GE">Georgia</option>
      <option value="DE">Germany</option>
      <option value="GH">Ghana</option>
      <option value="GI">Gibraltar</option>
      <option value="GR">Greece</option>
      <option value="GL">Greenland</option>
      <option value="GD">Grenada</option>
      <option value="GP">Guadeloupe</option>
      <option value="GU">Guam</option>
      <option value="GT">Guatemala</option>
      <option value="GG">Guernsey</option>
      <option value="GN">Guinea</option>
      <option value="GW">Guinea-Bissau</option>
      <option value="GY">Guyana</option>
      <option value="HT">Haiti</option>
      <option value="HM">Heard Island and McDonald Islands</option>
      <option value="VA">Holy See (Vatican City State)</option>
      <option value="HN">Honduras</option>
      <option value="HK">Hong Kong</option>
      <option value="HU">Hungary</option>
      <option value="IS">Iceland</option>
      <option value="IN">India</option>
      <option value="ID">Indonesia</option>
      <option value="IR">Iran, Islamic Republic of</option>
      <option value="IQ">Iraq</option>
      <option value="IE">Ireland</option>
      <option value="IM">Isle of Man</option>
      <option value="IL">Israel</option>
      <option value="IT">Italy</option>
      <option value="JM">Jamaica</option>
      <option value="JP">Japan</option>
      <option value="JE">Jersey</option>
      <option value="JO">Jordan</option>
      <option value="KZ">Kazakhstan</option>
      <option value="KE">Kenya</option>
      <option value="KI">Kiribati</option>
      <option value="KP">Korea, Democratic Peoples Republic of</option>
      <option value="KR">Korea, Republic of</option>
      <option value="KW">Kuwait</option>
      <option value="KG">Kyrgyzstan</option>
      <option value="LA">Lao Peoples Democratic Republic</option>
      <option value="LV">Latvia</option>
      <option value="LB">Lebanon</option>
      <option value="LS">Lesotho</option>
      <option value="LR">Liberia</option>
      <option value="LY">Libya</option>
      <option value="LI">Liechtenstein</option>
      <option value="LT">Lithuania</option>
      <option value="LU">Luxembourg</option>
      <option value="MO">Macao</option>
      <option value="MK">Macedonia, the former Yugoslav Republic of</option>
      <option value="MG">Madagascar</option>
      <option value="MW">Malawi</option>
      <option value="MY">Malaysia</option>
      <option value="MV">Maldives</option>
      <option value="ML">Mali</option>
      <option value="MT">Malta</option>
      <option value="MH">Marshall Islands</option>
      <option value="MQ">Martinique</option>
      <option value="MR">Mauritania</option>
      <option value="MU">Mauritius</option>
      <option value="YT">Mayotte</option>
      <option value="MX">Mexico</option>
      <option value="FM">Micronesia, Federated States of</option>
      <option value="MD">Moldova, Republic of</option>
      <option value="MC">Monaco</option>
      <option value="MN">Mongolia</option>
      <option value="ME">Montenegro</option>
      <option value="MS">Montserrat</option>
      <option value="MA">Morocco</option>
      <option value="MZ">Mozambique</option>
      <option value="MM">Myanmar</option>
      <option value="NA">Namibia</option>
      <option value="NR">Nauru</option>
      <option value="NP">Nepal</option>
      <option value="NL">Netherlands</option>
      <option value="NC">New Caledonia</option>
      <option value="NZ">New Zealand</option>
      <option value="NI">Nicaragua</option>
      <option value="NE">Niger</option>
      <option value="NG">Nigeria</option>
      <option value="NU">Niue</option>
      <option value="NF">Norfolk Island</option>
      <option value="MP">Northern Mariana Islands</option>
      <option value="NO">Norway</option>
      <option value="OM">Oman</option>
      <option value="PK">Pakistan</option>
      <option value="PW">Palau</option>
      <option value="PS">Palestinian Territory, Occupied</option>
      <option value="PA">Panama</option>
      <option value="PG">Papua New Guinea</option>
      <option value="PY">Paraguay</option>
      <option value="PE">Peru</option>
      <option value="PH">Philippines</option>
      <option value="PN">Pitcairn</option>
      <option value="PL">Poland</option>
      <option value="PT">Portugal</option>
      <option value="PR">Puerto Rico</option>
      <option value="QA">Qatar</option>
      <option value="RE">Réunion</option>
      <option value="RO">Romania</option>
      <option value="RU">Russian Federation</option>
      <option value="RW">Rwanda</option>
      <option value="BL">Saint Barthélemy</option>
      <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
      <option value="KN">Saint Kitts and Nevis</option>
      <option value="LC">Saint Lucia</option>
      <option value="MF">Saint Martin (French part)</option>
      <option value="PM">Saint Pierre and Miquelon</option>
      <option value="VC">Saint Vincent and the Grenadines</option>
      <option value="WS">Samoa</option>
      <option value="SM">San Marino</option>
      <option value="ST">Sao Tome and Principe</option>
      <option value="SA">Saudi Arabia</option>
      <option value="SN">Senegal</option>
      <option value="RS">Serbia</option>
      <option value="SC">Seychelles</option>
      <option value="SL">Sierra Leone</option>
      <option value="SG">Singapore</option>
      <option value="SX">Sint Maarten (Dutch part)</option>
      <option value="SK">Slovakia</option>
      <option value="SI">Slovenia</option>
      <option value="SB">Solomon Islands</option>
      <option value="SO">Somalia</option>
      <option value="ZA">South Africa</option>
      <option value="GS">South Georgia and the South Sandwich Islands</option>
      <option value="SS">South Sudan</option>
      <option value="ES">Spain</option>
      <option value="LK">Sri Lanka</option>
      <option value="SD">Sudan</option>
      <option value="SR">Suriname</option>
      <option value="SJ">Svalbard and Jan Mayen</option>
      <option value="SZ">Swaziland</option>
      <option value="SE">Sweden</option>
      <option value="CH">Switzerland</option>
      <option value="SY">Syrian Arab Republic</option>
      <option value="TW">Taiwan, Province of China</option>
      <option value="TJ">Tajikistan</option>
      <option value="TZ">Tanzania, United Republic of</option>
      <option value="TH">Thailand</option>
      <option value="TL">Timor-Leste</option>
      <option value="TG">Togo</option>
      <option value="TK">Tokelau</option>
      <option value="TO">Tonga</option>
      <option value="TT">Trinidad and Tobago</option>
      <option value="TN">Tunisia</option>
      <option value="TR">Turkey</option>
      <option value="TM">Turkmenistan</option>
      <option value="TC">Turks and Caicos Islands</option>
      <option value="TV">Tuvalu</option>
      <option value="UG">Uganda</option>
      <option value="UA">Ukraine</option>
      <option value="AE">United Arab Emirates</option>
      <option value="GB">United Kingdom</option>
      <option value="US">United States</option>
      <option value="UM">United States Minor Outlying Islands</option>
      <option value="UY">Uruguay</option>
      <option value="UZ">Uzbekistan</option>
      <option value="VU">Vanuatu</option>
      <option value="VE">Venezuela, Bolivarian Republic of</option>
      <option value="VN">Viet Nam</option>
      <option value="VG">Virgin Islands, British</option>
      <option value="VI">Virgin Islands, U.S.</option>
      <option value="WF">Wallis and Futuna</option>
      <option value="EH">Western Sahara</option>
      <option value="YE">Yemen</option>
      <option value="ZM">Zambia</option>
      <option value="ZW">Zimbabwe</option>
    </Fragment>
  ),
  type: () => (
    <Fragment>
      <option value="" disabled hidden>
        {t('selectFromList')}
      </option>
      <option value="Full-time">{t('jobType.fulltime')}</option>
      <option value="Part-time">{t('jobType.parttime')}</option>
      <option value="Contract">{t('jobType.contract')}</option>
      <option value="Temporary">{t('jobType.temporary')}</option>
      <option value="Volunteer">{t('jobType.volunteer')}</option>
      <option value="Other">{t('jobType.other')}</option>
    </Fragment>
  ),
  industry: (
    <Fragment>
      <option value="" disabled hidden>
        {t('selectFromList')}
      </option>
      {industries}
    </Fragment>
  ),
  industryJobPosting: (
    <Fragment>
      <option value="" disabled hidden>
        {t('selectFromList')}
      </option>
      <option value="See Description">{t('seeDescription')}</option>
      {industries}
    </Fragment>
  ),
  industryCustomList: (customIndustries) => (
    <Fragment>
      <option value="" disabled hidden>
        {t('selectFromList')}
      </option>
      <option value="See Description">{t('seeDescription')}</option>
      {customIndustries}
    </Fragment>
  ),
  jobFunctionJobPosting: (
    <Fragment>
      <option value="" disabled hidden>
        {t('selectFromList')}
      </option>
      <option value="See Description">{t('seeDescription')}</option>
      {jobFunctions}
    </Fragment>
  ),
  jobFunction: (
    <Fragment>
      <option value="" disabled hidden>
        {t('selectFromList')}
      </option>
      {jobFunctions}
    </Fragment>
  ),
  jobFunctionOptional: (
    <Fragment>
      <option value="">Select job function</option>
      {jobFunctions}
    </Fragment>
  ),
  experience: () => (
    <Fragment>
      <option value="" disabled hidden>
        {t('selectFromList')}
      </option>
      <option value="0-1 years">0-1 {t('years')}</option>
      <option value="1-2 years">1-2 {t('years')}</option>
      <option value="3-5 years">3-5 {t('years')}</option>
      <option value="5-7 years">5-7 {t('years')}</option>
      <option value="7-10 years">7-10 {t('years')}</option>
      <option value="10+ years">10 {t('years')}+</option>
      <option value="See Description">{t('seeDescription')}</option>
    </Fragment>
  ),
  educationLevel: () => (
    <Fragment>
      <option value="" disabled hidden>
        {t('selectFromList')}
      </option>
      <option value="High School">{t('educationLevel.highschool')}</option>
      <option value="Some College">{t('educationLevel.somecollege')}</option>
      <option value="Associates">{t('educationLevel.associates')}</option>
      <option value="Bachelor's">{t('educationLevel.bachelors')}</option>
      <option value="Master's">{t('educationLevel.masters')}</option>
      <option value="PhD">{t('educationLevel.phd')}</option>
      <option value="MD">{t('educationLevel.md')}</option>
      <option value="See Description">{t('seeDescription')}</option>
    </Fragment>
  ),
  occupation: () => (
    <Fragment>
      <option>{t('selectAnOption')}</option>
      <option value="Jobseeker">{t('jobseeker')}</option>
      <option value="Current Student">{t('currentStudent')}</option>
      <option value="Recent Graduate">{t('recentGraduate')}</option>
      <option value="Employee">{t('employee')}</option>
      <option value="Referrer">{t('referrer')}</option>
    </Fragment>
  ),
  workPreference: () => (
    <Fragment>
      <option>{t('selectAnOption')}</option>
      <option value="Full Time">{t('fullTime')}</option>
      <option value="Part Time">{t('partTime')}</option>
      <option value="Fully Remote">{t('fullyRemote')}</option>
      <option value="Office Only">{t('officeOnly')}</option>
      <option value="Hybrid">{t('hybrid')}</option>
    </Fragment>
  ),
};

export default Options;
