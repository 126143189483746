import GermanTranslation from './de-DE/translation.json';
import UsEnglishTranslation from './en-US/translation.json';
import UkEnglishTranslation from './en-GB/translation.json';
import SpanishTranslation from './es-ES/translation.json';
import LatAmSpanishTranslation from './es-419/translation.json';
import CaFrenchTranslation from './fr-CA/translation.json';
import ItalianTranslation from './it-IT/translation.json';

export default {
  GermanTranslation,
  UsEnglishTranslation,
  UkEnglishTranslation,
  SpanishTranslation,
  LatAmSpanishTranslation,
  CaFrenchTranslation,
  ItalianTranslation,
};
