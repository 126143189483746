import { i18n } from '@alirosoftware/web-localization';
import Cookies from 'cookies-js';
import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SESSION_STORAGE_KEYS from '../constants/SessionStorageConstants';
import useAppContext from '../context/app-context';
import { useAuthContext } from '../context/auth-context';
import { useDisplayModeContext } from '../context/DisplayModeContext';
import { useLoginSignupContext } from '../context/LoginSignupModalContext';
import { useWelcomeContext } from '../context/WelcomeModalContext';
import { ensureRole } from '../helpers/helpers';

type AfterLoginData = {
  detail: {
    userData: any;
    employerData: any;
  } | null;
  wasSignedUp?: boolean;
  skipWebAuthn?: boolean;
};

export const useAfterLogin = () => {
  const { setWelcomeModalOpen } = useWelcomeContext();
  const { isPwaOrMobile } = useDisplayModeContext();
  const { refreshUserData } = useAuthContext();
  const { config } = useAppContext();
  const { closeLoginSignupModal } = useLoginSignupContext();
  const navigate = useNavigate();

  const afterLogin = async (data: AfterLoginData) => {
    let userData, employerData;
    const nextUrl = Cookies.get(SESSION_STORAGE_KEYS.USER.NEXT_URL_AFTER_LOGIN);
    try {
      const result = await refreshUserData();
      userData = result.userData;
      employerData = result.employerData;
    } catch (e) {
      if (nextUrl) {
        // full page reload to the current site with nextUrl at the end
        window.location.href = `${window.location.origin}${nextUrl}`;
      }
    } finally {
      Cookies.expire(SESSION_STORAGE_KEYS.USER.NEXT_URL_AFTER_LOGIN, {
        // @ts-ignore
        domain: `.${config.platformDomain}`,
      });
    }

    closeLoginSignupModal();

    localStorage?.setItem('i18nextLng', userData.preferences.defaultLanguage.value);
    localStorage?.setItem('LngFlag', userData.preferences.defaultLanguage.flag);
    i18n.changeLanguage(localStorage?.getItem('i18nextLng'));

    setTimeout(() => {
      if (nextUrl && nextUrl !== '/') {
        navigate({ pathname: nextUrl });
      } else if (window.location.pathname.includes('signup')) {
        navigate({ pathname: '/' });
      } else if (window.location.pathname === '/') {
        if (isPwaOrMobile) {
          navigate({ pathname: '/' });
        } else if (ensureRole(userData, ['admin'])) {
          navigate({ pathname: '/admin' });
        } else if (ensureRole(userData, ['erp_company_admin'])) {
          navigate({ pathname: '/employers/dashboard' });
        } else if (ensureRole(userData, ['erp_admin'])) {
          navigate({ pathname: '/employers/dashboard' });
        } else if (ensureRole(userData, ['erp_recruiter'])) {
          navigate({ pathname: '/employers/candidates' });
        } else if (ensureRole(userData, ['erp_user'])) {
          navigate({ pathname: '/' });
        } else if (ensureRole(userData, ['core_user'])) {
          navigate({ pathname: '/' });
          // @ts-ignore
        } else if (!config.isOnCore()) {
          navigate({ pathname: '/' });
        }
      }
    }, 10);

    // @ts-ignore
    if (data.wasSignedUp && config.isOnCore()) {
      setWelcomeModalOpen(true);
    }
  };

  return { afterLogin };
};

export const withAfterLogin = (Component: React.ComponentType<any>) => {
  return forwardRef((props: any, ref) => {
    const { afterLogin } = useAfterLogin();

    return <Component {...props} afterLogin={afterLogin} ref={ref} />;
  });
};
