import { User } from '@alirosoftware/core-types';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import languages from '../constants/Languages';
import { useLoginSignupContext } from '../context/LoginSignupModalContext';
import { useAfterLogin } from './useAfterLogin';

type CreateAccountParams = {
  veteran: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  jobTitle?: string;
  jobFunction?: string;
  employeeId?: string;
  defaultLanguage: string;
  flag: string;
  source: string;
  token?: string;
};

/**
 * Takes VALID form data and creates an account
 */
const useCreateAccount = () => {
  const { t } = useTranslation();

  const { afterLogin } = useAfterLogin();
  const context = useLoginSignupContext();

  return useMutation({
    mutationKey: ['createAccount'],
    mutationFn: async (data: CreateAccountParams) => {
      try {
        const response = await axios.post<User>('/api/v2/user/signup', {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          defaultLanguage: { value: data.defaultLanguage },
          employeeId: data.employeeId,
          veteran: data.veteran,
          ...(data.jobTitle && { jobTitle: data.jobTitle, jobFunction: data.jobFunction }),
          ...(context.referralChannel && { referralChannel: context.referralChannel }),
          ...(context.referralSource && { referralSource: context.referralSource }),
          ...(context.referralUserId && { referralUserId: context.referralUserId }),
          ...(context.signupToken && { signupToken: context.signupToken }),
        });
        return response.data;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 409 || error.response?.status === 400) {
            throw t('registrationFailed');
          }
        }
        throw undefined;
      }
    },
    onError: (err: string): string => err as string,
    onSuccess: async (body) => {
      context.setReferralSource();
      ReactGA.event({
        category: 'help',
        action: 'Account Created',
      });
      if (body.isVerified === false) {
        // If a ERP user has signed up with an ERP email, we need to show the prmopt to verify their email
      } else {
        // Otherwise they don't need anotehr prompt, and we can continue with logging them in immediately.
        afterLogin({
          detail: null,
          wasSignedUp: true,
        });
      }
    },
  });
};

export default useCreateAccount;
