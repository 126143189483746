const SESSION_STORAGE_KEYS = {
  USER: {
    DATA: 'userData',
    PRE_LOGIN_JOB: 'preLoginJobData',
    NEXT_URL_AFTER_LOGIN: 'nextUrlAfterLogin',
    ALIRO_ID: '_aliroid',
    EXTERNAL_LOGIN_TYPE: 'externalLoginType',
    GOING_TO_AFTER_LOGIN: 'goingToAfterLogin',
    LOGIN_ERROR: 'loginerror',
    ERP_LOGIN_URL: 'erploginurl',
    HAS_TRIED_WEB_AUTHN: 'hasTriedWebAuthn',
  },
  EMPLOYER: {
    CANDIDATE_LIST_LATEST_QS: 'employerCandidateListLatestQs',
    DATA: 'employerData',
    DASHBOARD_LATEST_QS: 'employerDashboardLatestQs',
  },
  ACTION: {
    LAST_MODIFIED_JOB_ID: 'action_last_modified_job_id',
    EXPRESSED_INTEREST_VIA_SOCIAL: 'expressedInterestViaSocial',
    WANTED_TO_VETERAN_ADVOCACY: 'wantedToVeteranAdvocacy',
    WANTED_TO_HR_CONNECT: 'wantedToHrConnect',
    SEEN_RECALCULATE_INTRO: 'seenRecalculateIntro',
  },
  PREFERENCES: {
    SCREEN: {
      PARTNER: {
        ANALYTICS: {
          START_DATE: 'screen_employer_analytics_start_date',
          END_DATE: 'screen_employer_analytics_end_date',
          GROUP_BY: 'screen_employer_analytics_group_by',
        },
      },
      EMPLOYER: {
        SELECTED: 'screen_employer_selected',
        JOBS: {
          RECRUITER: 'screen_employer_jobs_filtered_recruiter',
          PAGE_NUM: 'screen_employer_jobs_page_num',
          PER_PAGE: 'screen_employer_jobs_per_page',
          SEARCH_TEXT: 'screen_employer_jobs_search_text',
          STATUS: 'screen_employer_jobs_status',
          SORT_FIELD: 'screen_employer_jobs_sort_field',
          SORT_ORDER: 'screen_employer_jobs_sort_order',
        },
        ANALYTICS: {
          START_DATE: 'screen_employer_analytics_start_date',
          END_DATE: 'screen_employer_analytics_end_date',
          GROUP_BY: 'screen_employer_analytics_group_by',
          JOB_FILTER: 'screen_employer_analytics_job_filter',
          RECRUITER_FILTER: 'screen_employer_analytics_recruiter_filter',
        },
      },
    },
  },
  REFERRAL: {
    CHANNEL: 'referralChannel',
    JOB_ID: 'referralJobId',
    SIGNUP_TOKEN: 'signupToken',
    SOURCE: 'referralSource',
    USER_ID: 'referralUserId',
  },
};

export default SESSION_STORAGE_KEYS;
