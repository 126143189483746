import { ReferralChannel, ReferralSource } from '@alirosoftware/core-types/client';
import { browserSupportsWebAuthn, startAuthentication, startRegistration } from '@simplewebauthn/browser';
import { createContext, forwardRef, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import SESSION_STORAGE_KEYS from '../constants/SessionStorageConstants';
import { sessionStorageClear } from '../helpers/helpers';
import {
  addHasTriedWebAuthnCookie,
  clearLocalstorageWebauthnRegisteredEmails,
  getHasTriedWebAuthnCookie,
  getLocalstorageWebauthnRegisteredEmails,
  getLoginChallengeOptions,
  getRegisterChallengeOptions,
  postVerifyLogin,
  postVerifyRegistration,
  updateLocalstorageWithWebauthnRegistration,
} from '../helpers/webauthn';
import { useAuthContext } from './auth-context';

export type LoginSignupContextState = {
  afterClose?: () => void;
  email?: string;
  firstName?: string;
  isModalOpen?: boolean;
  jobFunction?: string;
  jobTitle?: string;
  lastName?: string;
  showJobFields?: boolean;
  veteran?: boolean;
};

export type LoginSignupContextValue = LoginSignupContextState & {
  closeLoginSignupModal: () => void;
  openLoginSignupModal: () => void;
  referralChannel: ReferralChannel;
  referralJobId: string;
  referralSource: ReferralSource;
  referralUserId: string;
  setLoginSignupState: (value: LoginSignupContextState) => void;
  setReferralChannel: (value?: ReferralChannel) => void;
  setReferralJobId: (value?: string) => void;
  setReferralSource: (value?: ReferralSource) => void;
  setReferralUserId: (value?: string) => void;
  setSignupToken: (value?: string) => void;
  signupToken: string;
  tryLoginWebAuthn: () => Promise<any>;
  tryRegisterWebAuthn: (email: any) => Promise<void>;
};

export const LoginSignupContext = createContext<LoginSignupContextValue>({
  closeLoginSignupModal: (): void => {},
  isModalOpen: false,
  openLoginSignupModal: (): void => {},
  referralChannel: null,
  referralJobId: null,
  referralUserId: null,
  setLoginSignupState: (): void => {},
  setReferralChannel: (): void => {},
  setReferralJobId: (): void => {},
  setReferralUserId: (): void => {},
  setSignupToken: (): void => {},
  setReferralSource: (): void => {},
  referralSource: null,
  signupToken: null,
  tryRegisterWebAuthn: (): Promise<void> => Promise.resolve(),
  tryLoginWebAuthn: (): Promise<void> => Promise.resolve(),
});

export const LoginSignupProvider = ({ children }: PropsWithChildren) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { user } = useAuthContext();
  const [state, setState] = useState<LoginSignupContextState>({
    isModalOpen: !!window.location.search.includes('openlogin'),
  });
  const [registeredEmail, setRegisteredEmail] = useState<string>(getLocalstorageWebauthnRegisteredEmails());
  const [referralChannelState, setReferralChannelState] = useState<ReferralChannel>(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.CHANNEL) as ReferralChannel,
  );
  const [referralJobIdState, setReferralJobIdState] = useState<string>(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.JOB_ID),
  );
  const [referralUserIdState, setReferralUserIdState] = useState<string>(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.USER_ID),
  );
  const [referralSourceState, setReferralSourceState] = useState<ReferralSource>(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.SOURCE) as ReferralSource,
  );
  const [signupTokenState, setSignupTokenState] = useState<string>(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.SIGNUP_TOKEN),
  );

  const updateState = useCallback(
    (newState: Partial<LoginSignupContextState>) => {
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const closeLoginSignupModal = useCallback(() => {
    updateState({ isModalOpen: false });
    if (typeof state.afterClose === 'function') {
      state.afterClose();
    }
  }, [updateState, state.afterClose]);

  const openLoginSignup = useCallback((): void => updateState({ isModalOpen: true }), [updateState]);

  const setLoginSignupState = useCallback(
    (value: LoginSignupContextState): void => {
      const newState = {
        afterClose: value.afterClose,
        email: value.email || '',
        firstName: value.firstName || '',

        jobFunction: value.jobFunction || '',
        jobTitle: value.jobTitle || '',
        lastName: value.lastName || '',
        showJobFields: value.showJobFields || false,
        veteran: value.veteran || false,
      };

      if (value.isModalOpen != undefined) {
        newState['isModalOpen'] = value.isModalOpen;
      }

      updateState(newState);

      sessionStorageClear();
    },
    [updateState],
  );

  const setReferralChannel = useCallback(
    (value?: ReferralChannel): void => {
      if (value) {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.CHANNEL, value);
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.REFERRAL.CHANNEL);
      }
      setReferralChannelState(value);
    },
    [setReferralChannelState],
  );

  const setReferralJobId = useCallback(
    (value?: string): void => {
      if (value) {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.JOB_ID, value);
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.REFERRAL.JOB_ID);
      }
      setReferralJobIdState(value);
    },
    [setReferralJobIdState],
  );

  const setReferralSource = useCallback(
    (value?: ReferralSource): void => {
      if (value) {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.SOURCE, value);
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.REFERRAL.SOURCE);
      }
      setReferralSourceState(value);
    },
    [setReferralSourceState],
  );

  const setReferralUserId = useCallback(
    (value?: string): void => {
      if (value) {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.USER_ID, value);
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.REFERRAL.USER_ID);
      }
      setReferralUserIdState(value);
    },
    [setReferralUserIdState],
  );

  const setSignupToken = useCallback(
    (value?: string): void => {
      if (value) {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.SIGNUP_TOKEN, value);
      } else {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.REFERRAL.SIGNUP_TOKEN);
      }
      setSignupTokenState(value);
    },
    [setSignupTokenState],
  );

  const tryLoginWebAuthn = useCallback(async () => {
    console.log('tryLoginWebAuthn', registeredEmail);
    if (!browserSupportsWebAuthn() || !registeredEmail) {
      return;
    }

    const hasTriedCookie = getHasTriedWebAuthnCookie();
    if (hasTriedCookie) {
      return;
    }

    const options = await getLoginChallengeOptions(registeredEmail);
    const loginResponse = await startAuthentication(options).catch((err) => {
      if (err?.toString().includes('NotAllowedError')) {
        clearLocalstorageWebauthnRegisteredEmails();
        setRegisteredEmail('');
      }
      console.error(`Error logging in with WebAuthn!`, err);
      throw new Error(`Error logging in with WebAuthn!`);
    });

    addHasTriedWebAuthnCookie();

    return await postVerifyLogin(registeredEmail, loginResponse);
  }, [registeredEmail]);

  const tryRegisterWebAuthn = useCallback(
    async (email: string) => {
      console.log('tryRegisterWebAuthn', user);
      if (!browserSupportsWebAuthn() || !email) {
        throw new Error(`Error registering with WebAuthn!`);
      }

      const options = await getRegisterChallengeOptions();
      const registrationResponse = await startRegistration(options).catch((err) => {
        console.error(`Error registering with WebAuthn!`, err);
        throw new Error(`Error registering with WebAuthn!`);
      });

      const { credentialId } = await postVerifyRegistration(registrationResponse);
      updateLocalstorageWithWebauthnRegistration(email, credentialId);
    },
    [user.data?.email],
  );

  useEffect((): void => {
    const referralChannel = (urlSearchParams.get('referralChannel') as ReferralChannel) || referralChannelState;
    const referralSource = (urlSearchParams.get('referralSource') as ReferralSource) || referralSourceState;
    const referralUserId = urlSearchParams.get('referralUserId') || referralUserIdState;

    if (referralChannel && referralSource) {
      setReferralChannel(referralChannel);
      setReferralSource(referralSource);

      if (referralUserId) {
        setReferralUserId(referralUserId);
      }
    }
  }, [urlSearchParams]);

  return (
    <LoginSignupContext.Provider
      value={{
        ...state,
        closeLoginSignupModal: closeLoginSignupModal,
        openLoginSignupModal: openLoginSignup,
        referralChannel: referralChannelState,
        referralJobId: referralJobIdState,
        referralSource: referralSourceState,
        referralUserId: referralUserIdState,
        setLoginSignupState,
        setReferralChannel,
        setReferralJobId,
        setReferralSource,
        setReferralUserId,
        setSignupToken,
        signupToken: signupTokenState,
        tryLoginWebAuthn,
        tryRegisterWebAuthn,
      }}
    >
      {children}
    </LoginSignupContext.Provider>
  );
};

export const useLoginSignupContext = () => useContext(LoginSignupContext);

export const withLoginSignupContext = (Component: any) =>
  forwardRef((props: any, ref) => (
    <LoginSignupContext.Consumer>
      {(context) => <Component {...props} loginSignupContext={context} ref={ref} />}
    </LoginSignupContext.Consumer>
  ));
