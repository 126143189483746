import { Button, ButtonColor, FormInput } from '@alirosoftware/web-components';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import request from 'superagent';
import * as yup from 'yup';

type Props = {
  initialEmail?: string;
  onSuccess?: () => void;
  onError?: (message: string) => void;
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const ResetPasswordForm: FC<Props> = ({ initialEmail = '', onError, onSuccess }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const sendResetEmail = async ({ email }) => {
    try {
      setErrorMessage(undefined);
      setLoading(true);
      const response = await request.post('/auth/user/password/token').send({ email: email });
      if (response.ok && onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const errorMessage = error.response.body.message;
      setErrorMessage(errorMessage);
      if (onError) {
        onError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-modal">
      <h2>{t('forgotPassword')}</h2>
      <p>{t('emailToReset')}</p>
      <Formik initialValues={{ email: initialEmail }} onSubmit={sendResetEmail} validationSchema={schema}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="w-full flex flex-col">
              <FormInput name="email" type="email" label="Email" placeholder={t('enter') + ' ' + t('emailAddress')} />
              {!!errorMessage && <div className="text-error">{errorMessage}</div>}
              <Button data-cy="forgot-password-button" type="submit" loading={loading} color={ButtonColor.Blue}>
                {t('sendMeLink')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
