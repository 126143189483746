import { Grid } from '../../core/layout/grid/grid';
import { Button, ButtonColor, ButtonPull } from '../../core/button/button';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { UseCreateFolderOpts } from '../hooks/useCreateFolder';
import { MediaBackendAdapter } from '../types';

export type CreateMediaFolderFormProps = Pick<UseCreateFolderOpts, 'onFolderCreated'> & {
  parentId: string | null;
  backendAdapter: MediaBackendAdapter;
};

const validationSchema = object().shape({
  name: string().min(3).label('Folder name'),
});

export const CreateMediaFolderForm = ({ parentId, backendAdapter, onFolderCreated }: CreateMediaFolderFormProps) => {
  const { t } = useTranslation();
  const { error, createFolder } = backendAdapter.useCreateFolder({ onFolderCreated });

  return (
    <Grid.Container fluid>
      <Grid.Row>
        <Grid.Column colspan={12}>
          <h3>{t('mediaManager.createFolder')}</h3>
        </Grid.Column>
      </Grid.Row>

      {error && (
        <Grid.Row className="error-messages">
          <Grid.Column colspan={12}>
            <p>{error}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column colspan={12}>
          <Formik
            initialValues={{ name: '', parentId }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={createFolder}
          >
            {({ handleSubmit, handleChange, handleBlur, isSubmitting, errors }) => (
              <form onSubmit={handleSubmit}>
                <label htmlFor="name" className="text-sm u-mb-0">
                  {t('mediaManager.folderName')}
                </label>
                {errors.name ? <p className="error-message">{errors.name}</p> : null}
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="u-full-width"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Button type="submit" pull={ButtonPull.Right} color={ButtonColor.Primary} loading={isSubmitting}>
                  {t('mediaManager.createFolder')}
                </Button>
              </form>
            )}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
};
