import * as Yup from 'yup';
import { LOCALE_LABELS } from './types';

export const emailValidationSchema = Yup.object().shape({
  locale: Yup.string().required().oneOf(Object.keys(LOCALE_LABELS)).label('Locale'),
  subject: Yup.string().label('Subject'),
  body: Yup.string().label('Body'),
});

export const notificationValidationSchema = Yup.object().shape({
  locale: Yup.string().required().oneOf(Object.keys(LOCALE_LABELS)).label('Locale'),
  title: Yup.string().min(5).max(100).required(),
  body: Yup.string().min(20).max(500).required(),
  link: Yup.string().min(5).max(100),
  linkText: Yup.string().min(4).max(50),
  sendNotification: Yup.boolean().required(),
  expiryDays: Yup.number().min(0).max(365).required(),
});

export const smsValidationSchema = Yup.object().shape({
  locale: Yup.string().required().oneOf(Object.keys(LOCALE_LABELS)).label('Locale'),
  body: Yup.string().label('Body').min(20).max(500),
});
