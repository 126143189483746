import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'cookies-js';
import SESSION_STORAGE_KEYS from '../constants/SessionStorageConstants';
import useAppContext from '../context/app-context';
import { useAfterLogin } from '../hooks/useAfterLogin';
import localAuth from './local-auth';

const useAttemptLogin = () => {
  const { config } = useAppContext();
  const { afterLogin } = useAfterLogin();
  const queryClient = useQueryClient();

  type AttempLoginParams = Record<'email' | 'password' | 'token', string>;
  return useMutation<AxiosResponse, AxiosError<any>, AttempLoginParams>({
    mutationKey: ['email-login'],
    mutationFn: async ({ email, password, token }) => {
      localAuth.clearLocalAuth();
      return await axios.post(
        `/auth/user/login?token=${encodeURIComponent(token)}`,
        {
          email,
          password,
        },
        {
          validateStatus: (status) => status === 200,
        },
      );
    },
    onSuccess: async (response) => {
      Cookies.set(SESSION_STORAGE_KEYS.USER.EXTERNAL_LOGIN_TYPE, 'NONE', {
        domain: `.${config.platformDomain}`,
        secure: process.env.NODE_ENV === 'production',
      });
      if (response.data.userData) {
        localAuth.storeLocalUser(response.data.userData);
      }
      if (response.data.employerData) {
        localAuth.storeLocalEmployer(response.data.employerData);
      }
      queryClient.refetchQueries(['auth']).then(() => {
        afterLogin({ detail: response.data });
      });
    },
    onError: (error) => {
      const response = error.response;
      if (response.status === 403 && response.data?.host) {
        window.location = `${response.data.host}/email-verification/email/${response.data.email}` as any;
      }
    },
  });
};

export default useAttemptLogin;
