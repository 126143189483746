import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';

export type DisplayMode = 'web' | 'pwa';

export type DisplayModeContextType = {
  displayMode: DisplayMode;
  isMobile: boolean;
  isPwaOrMobile: boolean;
};

export const DisplayModeContext = createContext<DisplayModeContextType>({
  displayMode: 'web',
  isMobile: false,
  isPwaOrMobile: false,
});

const MAX_MOBILE_WIDTH = 768;

const getDisplayMode = (): DisplayMode => {
  let displayMode: DisplayMode = 'web';
  const mqStandAlone = '(display-mode: standalone)';

  // @ts-ignore
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'pwa';
  }

  return displayMode;
};

export const DisplayModeProvider = ({ children }: PropsWithChildren) => {
  const displayMode = useRef<DisplayMode>(getDisplayMode());

  const isMobile = useMemo(() => window?.innerWidth <= MAX_MOBILE_WIDTH, [window?.innerWidth]);

  return (
    <DisplayModeContext.Provider
      value={{
        displayMode: displayMode?.current || 'web',
        isMobile,
        isPwaOrMobile: isMobile || displayMode?.current === 'pwa',
      }}
    >
      {children}
    </DisplayModeContext.Provider>
  );
};

export const useDisplayModeContext = () => useContext(DisplayModeContext);
