import { Button, ButtonVariant, Icon } from '@alirosoftware/web-components';
import classnames from 'classnames';
import Cookies from 'cookies-js';
import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation, withTranslation } from 'react-i18next';
import { AiFillApple } from 'react-icons/ai';
import { FaFacebookF, FaFacebookMessenger, FaLinkedinIn } from 'react-icons/fa';
import BUTTON_CONSTANTS from '../../constants/ButtonConstants';
import SESSION_STORAGE_KEYS from '../../constants/SessionStorageConstants';
import useAppContext from '../../context/app-context';
import { useLoginSignupContext } from '../../context/LoginSignupModalContext';

export type SocialButtonComponentProps = {
  buttonTextOverride?: string;
  network: string;
  type: string;
  onClick?: () => void;
  ga?: {
    category: string;
    action: string;
    label?: string;
  };
  asLogo?: boolean;
  asText?: boolean;
};

const SocialButtonComponent = ({
  buttonTextOverride,
  ga = {
    category: '',
    action: '',
  },
  onClick = null,
  network = BUTTON_CONSTANTS.NETWORK.LINKEDIN,
  type = BUTTON_CONSTANTS.TYPE.SIGN_UP,
  asLogo = false,
  asText = false,
}: SocialButtonComponentProps) => {
  const { config } = useAppContext();
  const { t } = useTranslation();
  const { referralChannel, referralSource, referralUserId, signupToken } = useLoginSignupContext();

  const handleOnClick = () => {
    ReactGA.event(ga);
    if (onClick) {
      onClick();
    } else {
      let path;
      switch (network) {
        case BUTTON_CONSTANTS.NETWORK.LINKEDIN:
          path = '/auth/linkedin';
          break;
        case BUTTON_CONSTANTS.NETWORK.FACEBOOK:
          path = '/auth/facebook';
          break;
        case BUTTON_CONSTANTS.NETWORK.APPLE:
          path = '/auth/apple';
          break;
        case BUTTON_CONSTANTS.NETWORK.SSO:
        case BUTTON_CONSTANTS.NETWORK.SSOEMPLOYEES:
          path = '/auth/sso';
          break;
        default:
          break;
      }

      let queryString = {
        ...(referralChannel && { referralChannel }),
        ...(referralSource && { referralSource }),
        ...(referralUserId && { referralUserId }),
        ...(signupToken && { signupToken }),
      };

      if (Object.keys(queryString).length) {
        path += `?${new URLSearchParams(queryString).toString()}`;
      }

      let nextLocation = document.location.pathname + document.location.search;
      nextLocation = nextLocation.replace('?openlogin=true', '').replace('?auth=expired&openlogin=true', '');
      Cookies.set(SESSION_STORAGE_KEYS.USER.GOING_TO_AFTER_LOGIN, nextLocation, {
        // @ts-ignore
        domain: `.${config.platformDomain}`,
        secure: process.env['NODE_ENV'] === 'production',
      });
      Cookies.set(SESSION_STORAGE_KEYS.USER.EXTERNAL_LOGIN_TYPE, network, {
        // @ts-ignore
        domain: `.${config.platformDomain}`,
        secure: process.env['NODE_ENV'] === 'production',
      });
      document.location.assign(document.location.origin + path);
    }
  };

  let typeClass;
  let buttonText;
  let icon;
  let cydata;
  switch (type) {
    case BUTTON_CONSTANTS.TYPE.SIGN_UP:
      buttonText = t('continueWith');
      break;
    case BUTTON_CONSTANTS.TYPE.SIGN_IN:
      buttonText = t('continueWith');
      break;
    case BUTTON_CONSTANTS.TYPE.SHARE:
      buttonText = t('shareOn');
      break;
    case BUTTON_CONSTANTS.TYPE.SEND:
      buttonText = t('sendOn');
      break;
    default:
      break;
  }

  switch (network) {
    case BUTTON_CONSTANTS.NETWORK.LINKEDIN:
      typeClass = 'button-linkedin';
      buttonText += ' LinkedIn';
      icon = FaLinkedinIn;
      cydata = 'linkedin-login';
      break;
    case BUTTON_CONSTANTS.NETWORK.FACEBOOK:
      typeClass = 'button-facebook';
      buttonText += ' Facebook';
      icon = FaFacebookF;
      cydata = 'facebook-login';
      break;
    case BUTTON_CONSTANTS.NETWORK.MESSENGER:
      typeClass = 'button-facebook';
      buttonText += ' Facebook Messenger';
      icon = FaFacebookMessenger;
      cydata = 'messenger-login';
      break;
    case BUTTON_CONSTANTS.NETWORK.APPLE:
      typeClass = 'button-apple';
      buttonText += ' Apple';
      icon = AiFillApple;
      cydata = 'apple-login';
      break;
    case BUTTON_CONSTANTS.NETWORK.SSO:
      typeClass = 'button-sso';
      buttonText += ' SSO';
      cydata = 'sso-login';
      break;
    case BUTTON_CONSTANTS.NETWORK.SSOEMPLOYEES:
      typeClass = '';
      buttonText += ' SSO';
      cydata = 'sso-employees-login';
      break;
    default:
      break;
  }

  if (asLogo) {
    return (
      <div
        className={classnames(typeClass, 'cursor-pointer as-logo social-button')}
        role="button"
        onClick={handleOnClick}
        data-testid={cydata}
      >
        <Icon icon={icon} className="text-40 as-logo" />
      </div>
    );
  }

  if (asText) {
    return (
      <Button
        variant={ButtonVariant.Text}
        className={classnames('!text-blue-800 text-16 underline', typeClass)}
        fullWidth
        onClick={handleOnClick}
        testId={cydata}
      >
        {icon ? <Icon icon={icon} /> : null}
        {buttonTextOverride ?? buttonText}
      </Button>
    );
  }

  return (
    <Button className={classnames('social-button', typeClass)} fullWidth onClick={handleOnClick} testId={cydata}>
      {icon ? <Icon icon={icon} /> : null}
      {buttonTextOverride ?? buttonText}
    </Button>
  );
};

export default withTranslation()(SocialButtonComponent);
