import React from 'react';
import { VerificationModalContent } from '../../Settings/Sections/mfa/VerificationModal';
import { MfaCodeInput, MfaSessionDto } from '@alirosoftware/nest-mfa/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { QUERY_KEY_MFA_SESSION } from '../../Settings/Sections/mfa/useMfaSession';
import { useAfterLogin } from '../../../hooks/useAfterLogin';
import { useTranslation } from 'react-i18next';

export type EmailVerificationModalContentProps = { session: MfaSessionDto; onCancel: () => void };

export const EmailVerificationModalContent = ({ session, onCancel }: EmailVerificationModalContentProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { afterLogin } = useAfterLogin();
  const [verificationError, setVerificationError] = React.useState<string>();

  const { mutate: verifyEmail, isLoading: verifyingEmail } = useMutation<any, any, MfaCodeInput>({
    mutationFn: (data) => axios.post(`/api/v2/auth/mfa/${session.token}/verify-email`, data).then((r) => r.data),
    onSuccess: (detail) => {
      afterLogin({ detail, skipWebAuthn: true });
    },
    onError: (e: AxiosError<{ message?: string }>) => {
      setVerificationError(e?.response?.data?.message || 'An error occurred');
    },
  });

  const { mutate: sendEmail, isLoading: sendingEmail } = useMutation({
    mutationFn: () => axios.post(`/api/v2/auth/mfa/${session.token}/send-verification-email`).then((r) => r.data),
    onError: (e: AxiosError<{ message?: string }>) => {
      setVerificationError(e?.response?.data?.message || 'An error occurred');
      queryClient.invalidateQueries([QUERY_KEY_MFA_SESSION]);
    },
  });

  const onSubmitVerificationCode = async (code: string) => {
    try {
      verifyEmail({ code });
    } catch (e) {
      setVerificationError(e?.response?.data?.message || 'An error occurred');
    }
  };

  const onResendVerificationCode = async () => {
    sendEmail();
  };

  return (
    <VerificationModalContent
      title={t('mfaSettings.verifyIdentity')}
      body={
        <p className="text-lg text-offblack u-mt-2 u-mb-2">
          {t('mfaSettings.emailVerifyBody1') + ' '}
          <strong>{session.availableMethods.email}</strong>
          {'. ' + t('mfaSettings.emailVerifyBody2')}
        </p>
      }
      error={verificationError}
      submitText={t('mfaSettings.verifyIdentity')}
      resendText={t('mfaSettings.emailVerifyResend')}
      resentText={t('mfaSettings.emailVerifyResent')}
      onSubmit={onSubmitVerificationCode}
      onResend={onResendVerificationCode}
      onCancel={onCancel}
    />
  );
};
