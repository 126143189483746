import { useMemo } from 'react';
import { Grid } from '../../core/layout/grid/grid';
import { Formik } from 'formik';
import { Button, ButtonColor, ButtonPull } from '../../core/button/button';
import { CreateTemplateFormProps, Locale, LOCALE_LABELS, NotificationTemplateContent } from '../types';
import { useTranslation } from 'react-i18next';
import { FormSelectInput } from '../../core';
import { notificationValidationSchema } from '../validations';

export const CreateNotificationTemplateForm = ({
  templateName,
  templates: existingTemplates,
  backendAdapter,
  onTemplateUpdated,
}: CreateTemplateFormProps<NotificationTemplateContent>) => {
  const { t } = useTranslation();
  const { error, updateTemplate } = backendAdapter.useUpdateTemplate({ templateName, onTemplateUpdated });

  const defaultTemplate = useMemo(() => {
    return existingTemplates?.length > 0
      ? existingTemplates.find((t) => t.locale === 'en-US') || existingTemplates[0]
      : { title: '', body: '', link: '', linkText: '', sendNotification: true, expiryDays: 14 };
  }, [existingTemplates]);

  const missingLocales = useMemo(() => {
    return Object.keys(LOCALE_LABELS).filter(
      (locale) => !existingTemplates.some((t) => t.locale === locale),
    ) as Locale[];
  }, [existingTemplates]);

  return (
    <Formik
      initialValues={{
        title: defaultTemplate.title,
        body: defaultTemplate.body,
        link: defaultTemplate.link,
        linkText: defaultTemplate.linkText,
        sendNotification: defaultTemplate.sendNotification,
        expiryDays: defaultTemplate.expiryDays,
        locale: missingLocales[0],
      }}
      validationSchema={notificationValidationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={updateTemplate}
    >
      {({ handleSubmit, handleChange, handleBlur, isSubmitting, errors, values }) => (
        <Grid.Container fluid>
          {error && (
            <Grid.Row className="error-messages">
              <Grid.Column colspan={12}>
                <p>{error}</p>
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column colspan={12}>
              <form onSubmit={handleSubmit}>
                <FormSelectInput
                  name="locale"
                  label={t('emailTemplates.selectLocale')}
                  fullWidth={true}
                  placeholderValue=""
                  options={missingLocales.reduce(
                    (locales, locale) => {
                      locales[locale] = LOCALE_LABELS[locale];

                      return locales;
                    },
                    {} as Record<string, string>,
                  )}
                  autoComplete="off"
                />
                <Button type="submit" pull={ButtonPull.Right} color={ButtonColor.Primary} loading={isSubmitting}>
                  {t('emailTemplates.create')}
                </Button>
              </form>
            </Grid.Column>
          </Grid.Row>
        </Grid.Container>
      )}
    </Formik>
  );
};
