import { t } from 'i18next';
import SESSION_STORAGE_KEYS from '../constants/SessionStorageConstants';
import { getCurrencySymbol } from '../pages/JobPage/job-page-helpers';

const config = require('@alirosoftware/aliro-common').config();
/* global $, window, document, Blob, screen, sessionStorage, aliroConfig  */

const fileIsImg = function (file) {
  const imageType = /image/;
  return imageType.test(file.type);
};

const getSafeHTML = function (html) {
  return { __html: html };
};

/**
 *
 * removes non-alphanumeric characters and lowercases the string
 *
 * @param {string} str
 * @returns string
 */
const stringCleanAndLowercase = (str) =>
  typeof str === 'string' ? str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() : '';

/**
 *
 * @param {string} prefix
 * @param {string} value
 */
const enValueToLocalisationKey = (scope, value) =>
  typeof value === 'string' ? `${scope ? `${scope}.` : ''}${stringCleanAndLowercase(value)}` : '';

const dataURItoBlob = function (dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = window.atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

const responseErrorHandler = function (response = {}, callback) {
  let action = null;
  switch (response.statusCode) {
    case 401:
      sessionStorageClear();
      action = 'sessionCleared';
      break;
    default:
      break;
  }
  callback({ action });
};

const sessionStorageClear = function () {
  const jobReferralButtonClicked = sessionStorage.getItem('jobReferralButtonClicked');
  const referralChannel = sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.CHANNEL);
  const referralJobId = sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.JOB_ID);
  const referralUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.USER_ID);
  const referralSource = sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.SOURCE);
  const signupToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.REFERRAL.SIGNUP_TOKEN);

  sessionStorage.clear();

  if (jobReferralButtonClicked) {
    sessionStorage.setItem('jobReferralButtonClicked', jobReferralButtonClicked);
  }

  if (referralChannel) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.CHANNEL, referralChannel);
  }

  if (referralJobId) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.JOB_ID, referralJobId);
  }

  if (referralSource) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.SOURCE, referralSource);
  }

  if (referralUserId) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.USER_ID, referralUserId);
  }

  if (signupToken) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.REFERRAL.SIGNUP_TOKEN, signupToken);
  }

  sessionStorage.setItem('notLoggedIn', 'true');
};

const popupCenter = function (url, title, w, h) {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

  const width = window.innerWidth || document.documentElement.clientWidth || screen.width;
  const height = window.innerHeight || document.documentElement.clientHeight || screen.height;
  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = window.open(url, title, `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`);

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
};

const ensureRole = function (userData, roles) {
  if (!userData || !userData.roles) {
    return false;
  }

  if (!Array.isArray(roles)) {
    roles = [roles];
  }

  let valid = false;

  for (let i = 0; i < roles.length; i += 1) {
    if (userData.roles[roles[i]]) {
      valid = true;
      break;
    }
  }
  return valid;
};

const ensurePermission = function (userData, permission) {
  if (!userData || !userData.roles) {
    return false;
  }
  let valid = false;
  if (!aliroConfig.roles) {
    const config = require('@alirosoftware/aliro-common').config();
    aliroConfig.roles = config.roles.valid;
    aliroConfig.rolePermissions = config.rolePermissions;
  }
  for (let i = 0; i < aliroConfig.roles.length; i += 1) {
    const role = aliroConfig.roles[i];
    if (userData.roles[role] && aliroConfig.rolePermissions[role].includes(permission)) {
      valid = true;
      break;
    }
  }
  return valid;
};

const profileMinimal = function (userData) {
  return userData.firstName !== null && userData.lastName !== null;
};

const getSalaryRange = (job) => {
  if (
    !job ||
    !job.salaryRange ||
    job.salaryRange === 'Non-Exempt' ||
    job.salaryRange === 'NotSpecified' ||
    job.salaryRange === '0-50k'
  ) {
    return null;
  }

  const curr = getCurrencySymbol(job.currency);

  if (job.salaryRange === 'Hourly') {
    if (job.hourlyRate && !isNaN(job.hourlyRate)) {
      return `${t('pay')} ${curr}${Number(job.hourlyRate).toFixed(2)} ${t('anHour')}`;
    } else {
      console.warn(`helper.getSalaryRange - Invalid hourlyRate for Job ${job._id}`);
      return null;
    }
  }

  return `${curr}${job.salaryRange} ${t('pay')}`;
};

const mapCandidateStatus = (status) => config.candidate.status.map[status.toLowerCase()];

export {
  dataURItoBlob,
  ensurePermission,
  ensureRole,
  enValueToLocalisationKey,
  fileIsImg,
  getSafeHTML,
  getSalaryRange,
  mapCandidateStatus,
  popupCenter,
  profileMinimal,
  responseErrorHandler,
  sessionStorageClear,
};
